import React from 'react';
import { BoxProps, HStack, Image } from '@chakra-ui/react';
import { TokenInfoFormatted } from '../../../hooks/useTokenListFormatted';
import { addToken } from '../../../utils/metamaskWallet';
import { useWeb3WithDefault } from '../../../hooks/useWeb3WithDefault';
import { links } from '../../../config/links';

export type IconToolProps = {
    token: TokenInfoFormatted;
} & BoxProps;

const IconTool: React.FC<IconToolProps> = (props) => {
    const { token, ...rest } = props;
    const { chainId } = useWeb3WithDefault();
    return (
        <HStack spacing="5px" {...rest}>
            <Image
                boxSize="14px"
                cursor="pointer"
                src={process.env.PUBLIC_URL + '/assets/trade/copy.png'}
                fallbackSrc={process.env.PUBLIC_URL + '/assets/trade/copy.png'}
                onClick={() => {
                    navigator.clipboard.writeText(token.address);
                }}
            ></Image>
            <Image
                boxSize="22px"
                cursor="pointer"
                src={process.env.PUBLIC_URL + '/assets/trade/metamask.svg'}
                fallbackSrc={process.env.PUBLIC_URL + '/assets/trade/metamask.svg'}
                onClick={() => {
                    addToken(token.address, token.symbol, token.decimal, token.icon);
                }}
            ></Image>
            {/* <Image
                boxSize="22px"
                cursor="pointer"
                src={process.env.PUBLIC_URL + '/assets/trade/dashboard.svg'}
                fallbackSrc={process.env.PUBLIC_URL + '/assets/trade/dashboard.svg'}
                onClick={() => {
                    window.open(links.analyticLink + '/token?chainId=' + chainId + '&tokenAddress=' + token.address);
                }}
            ></Image> */}
            {/* <Image
                boxSize="20px"
                cursor="pointer"
                src={process.env.PUBLIC_URL + '/assets/trade/extralLink.svg'}
                fallbackSrc={process.env.PUBLIC_URL + '/assets/trade/extralLink.svg'}
                onClick={() => {
                    viewAddress(chainId, token.address);
                }}
            ></Image> */}
        </HStack>
    );
};

export default IconTool;
