import { useSelector } from 'react-redux';
import { useEffect, useMemo } from 'react';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';

import Home from './views/Home/Home';
import useAuth from './hooks/useAuth';
import { ChainId } from './types/mod';
import { i18nInitPromise } from './i18n';
import Swap from './views/Trade/Swap/Swap';
import { RootDispatch, RootState } from './state/store';
import Limit from './views/Trade/Limit/Limit';
import Wallet from './components/Wallet/Wallet';
import Locker from './views/VeiZi/Locker/Locker';
import LoadProvider from './providers/LoadProvider';
import AppLayout from './components/layout/AppLayout';
import Sidebar from './components/Sidebar/Sidebar/Sidebar';
import FarmFixedRangeiZi from './views/Farm/iZiSwap/FixRange/Farm';
// import FarmFixedRangeUni from './views/Farm/UniswapV3/FixRange/Farm';
// import FarmDynamicRangeUni from './views/Farm/UniswapV3/DynamicRange/DynamicRange';
import FarmDynamicRangeiZi from './views/Farm/iZiSwap/DynamicRange/DynamicRange';
// import FarmOneSideUni from './views/Farm/UniswapV3/OneSide/OneSide';
import FarmOneSideiZi from './views/Farm/iZiSwap/OneSide/OneSide';
import Pro from './views/Trade/Pro/Pro';
import VeNFTManage from './views/VeiZi/Locker/VeNFTManage';
import Liquidity from './views/Trade/Liquidity/Liquidity';
import NotFound404 from './views/NotFound404/NotFound404';
import MyOrders from './views/Trade/Limit/MyOrders/MyOrders';
import AddLiquidity from './views/Trade/Liquidity/AddLiquidity/AddLiquidity';
import useInterval from 'ahooks/lib/useInterval';
import { useRematchDispatch } from './hooks/useRematchDispatch';
import { useWeb3WithDefault } from './hooks/useWeb3WithDefault';
import { AUTO_LOGIN, CHAIN_CONFIG, DISABLE_CONSOLE } from './config/bizConfig';
import { getWeb3NoAccount } from './utils/web3NoAccount';
// import { ConnectorNames } from './utils/connectors';
import OrderHistory from './views/OrderHistory/OrderHistory';
import PrizeEvent from './views/Prize/PrizeEvent';
import Gallery from './views/Farm/Gallery/Gallery';
import useIsMobile from './hooks/useIsMobile';
import { config } from './config/sidebar/config';
import { LayoutStateEnum } from './state/models/pageLayout/pageLayout';
import { MobileHeader } from './views/components/MobileHeader/MobileHeader';
import Header from './views/components/Header/Header';
import useLayoutType from './hooks/useLayoutType';

import './App.css';
import { TabBar } from './components/Tabbar/Tabbar';
import AddCustomLiquidity from './views/Trade/Liquidity/AddLiquidity/Custom/AddCustomLiquidity';
import IPoints from './views/IPoints/IPoints';
import NetworkConnect from './views/components/NetworkConnect/NetworkConnect';
import Pools from './views/Pools/Pools';
import Portal from './views/Portal/Portal';
import Bridge from './views/Bridge/Bridge';
import { useColorMode } from '@chakra-ui/react';

// import '@openblockhq/dappsdk';

function App() {
    if (process.env.REACT_APP_ENV == 'production' && DISABLE_CONSOLE) {
        console.log = function () {};
    }

    useAutoRedirectToHome();
    useAutoRefreshBlockNumber();
    useAutoLogin();
    const { setColorMode } = useColorMode();
    setColorMode('dark');

    const promiseList = useMemo(() => [i18nInitPromise], []);
    const isMobile = useIsMobile();
    const layoutType = useLayoutType();
    const isHome = useIsHomePage();

    const Center = (
        <Switch>
            {/* <Route path="/home">
                <Home />
            </Route> */}
            <Route path="/farm" exact={true}>
                <FarmDynamicRangeiZi type={isMobile ? 'mobile' : 'pc'} />
            </Route>
            <Route path="/farm/fix">
                <FarmFixedRangeiZi type={isMobile ? 'mobile' : 'pc'} />
            </Route>
            <Route path="/farm/dynamic">
                <FarmDynamicRangeiZi type={isMobile ? 'mobile' : 'pc'} />
            </Route>
            <Route path="/farm/one-side">
                <FarmOneSideiZi type={isMobile ? 'mobile' : 'pc'} />
            </Route>
            <Route path="/liquidity" exact={true}>
                <Liquidity />
            </Route>
            <Route path="/add-liquidity/popular" exact={true}>
                <AddLiquidity />
            </Route>
            <Route path="/add-liquidity/custom" exact={true}>
                <AddCustomLiquidity />
            </Route>
            <Route path="/swap" exact={true}>
                <Swap />
            </Route>
            <Route path="/limit" exact={true}>
                <Limit />
            </Route>
            <Route path="/limit/my-orders" exact={true}>
                <MyOrders />
            </Route>
            <Route path="/pro" exact={true}>
                <Pro />
            </Route>
            <Route path="/order-history" exact={true}>
                <OrderHistory />
            </Route>
            <Route path="/iPoints" exact={true}>
                <IPoints />
            </Route>
            <Route path="/pools" exact={true}>
                <Pools />
            </Route>
            <Route path="/portal" exact={true}>
                <Portal />
            </Route>
            <Route path="/bridge" exact={true}>
                <Bridge />
            </Route>

            <Route path="*">
                <NotFound404 />
            </Route>
        </Switch>
    );

    return (
        <LoadProvider promiseList={promiseList}>
            {isMobile ? (
                <AppLayout header={<MobileHeader />} center={Center} right={<Wallet />} bottom={<TabBar config={config} />} />
            ) : layoutType === LayoutStateEnum.sidebar ? (
                <AppLayout left={<Sidebar />} center={Center} right={isHome ? <></> : <Wallet />} bottom={<NetworkConnect />} />
            ) : (
                <AppLayout header={<Header />} center={Center} />
            )}
        </LoadProvider>
    );
}

export default App;

function useAutoLogin() {
    const { loginConfig } = useSelector((state: RootState) => state);
    const { login } = useAuth();
    useEffect(() => {
        if (AUTO_LOGIN) {
            // if (loginConfig.lastLogin == ConnectorNames.Particle) return;
            login(loginConfig.lastLogin);
        }
    }, [login]);
}

function useAutoRefreshBlockNumber() {
    const { dispatch } = useRematchDispatch((dispatch: RootDispatch) => ({
        dispatch,
    }));
    const { web3 } = useWeb3WithDefault();
    const { chainId } = useWeb3WithDefault();

    const web3Valid = useMemo(() => {
        if (chainId === ChainId.Arbitrum) {
            return getWeb3NoAccount(ChainId.EthereumMainnet);
        }
        return web3;
    }, [web3, chainId]);

    useInterval(() => {
        dispatch.block.fetchBlockNumber(web3Valid);
    }, CHAIN_CONFIG.AUTO_REFRESH_BLOCK_NUMBER_INTERVAL);
}

// Redirect to /home if current pathname is /
function useAutoRedirectToHome() {
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        if (location.pathname === '/') history.push('/swap');
    }, [history, location.pathname]);
}

// Returns true if pathname is /home
function useIsHomePage() {
    const location = useLocation();
    return useMemo(() => location.pathname === '/swap', [location.pathname]);
}
