import { Image, useColorMode, Text, BoxProps, VStack, HStack } from '@chakra-ui/react';
import { i_h4, i_text_copy } from '../../../style';
import { getColorThemeSelector } from '../../../utils/funcs';
import { Modal } from '../Modal';

type Props = {
    isOpen: boolean;
    onClose: () => void;
    content: string | undefined;
} & BoxProps;
export const InsufficientModal: React.FC<Props> = (props) => {
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const { isOpen, onClose, content, ...rest } = props;

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            w={{ base: '341px', sm: '300px' }}
            title=""
            isShowClose={false}
            isShowHeader={false}
            modalBg={colorTheme('#ffffff', '#1F1F1F')}
            bg={colorTheme('#ffffff', '#00FFA1')}
            pb="20px"
            borderRadius={'10px'}
            alignItems="center"
            contentProps={{ px: '10px' }}
            {...rest}
        >
            <HStack h={{ base: '100%' }} spacing="16px" pt={{ base: '20px' }}>
                <Image boxSize="30px" src={colorTheme('/assets/modal/info.png', '/assets/modal/info.png')}></Image>
                <Text className={i_h4} color={colorTheme('#ffffff', '#000000')} textAlign="center" whiteSpace="pre-wrap">
                    {content}
                </Text>
            </HStack>
        </Modal>
    );
};
