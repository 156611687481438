import React, { useContext } from 'react';
import { Image, useDisclosure, Text, VStack, HStack, useColorMode, Divider, Box, Flex } from '@chakra-ui/react';
import CustomButton from '../iZUMi-UI-toolkit/src/components/Buttons/CustomButton/CustomButton';
import { ConnectorNames, connectorsArray, particleArray, ParticleConnectorNames } from '../utils/connectors';
import useAuth from '../hooks/useAuth';
import { i_h3, i_text_copy, i_text_d } from '../style';
import { getColorThemeSelector } from '../utils/funcs';
import { loginWithParticle } from '../utils/particle';
import useIsMobile from '../hooks/useIsMobile';
import { useRematchDispatch } from '../hooks/useRematchDispatch';
import { RootDispatch } from '../state/store';
import { Modal } from '../components/Modal/Modal';

const ConnectContext = React.createContext<{ onOpenModal: () => void }>(undefined!);

export const useConnectModal = () => {
    return useContext(ConnectContext);
};

const ConnectProvider: React.FC = ({ children }) => {
    const { login } = useAuth();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const isMobile = useIsMobile();
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const ua = navigator.userAgent;
    const isChrome = ua.indexOf('Chrome') > -1 && ua.indexOf('Safari') > -1 && ua.indexOf('Edge') == -1;
    const { dispatch } = useRematchDispatch((dispatch: RootDispatch) => ({
        dispatch,
    }));

    return (
        <ConnectContext.Provider value={{ onOpenModal: onOpen }}>
            <Modal
                isOpen={isOpen}
                onClose={onClose}
                title={'Connect'}
                w={{ base: '80%', lg: '528px' }}
                top="52%"
                zIndex="12"
                titleProps={{ pb: '11px' }}
                borderRadius="15px"
                border={colorTheme('', '1px solid #00FFA1')}
            >
                <VStack spacing="12px" mt={isMobile ? '20px' : 'unset'} alignItems="start">
                    <Text w="100%" className={i_text_d} fontSize="14px" color="#C1C1C1" pb="20px">
                        Connect wallet in one click to start using SURFI
                    </Text>
                    <HStack spacing="25px">
                        {connectorsArray.map(({ name, displayName, url }, i) => {
                            if (name == ConnectorNames.Particle) {
                                return;
                            }
                            return (
                                <CustomButton
                                    // w="180px"
                                    className={i_text_d}
                                    fontSize="17px"
                                    key={i}
                                    variant="none"
                                    radiusType="md"
                                    color={colorTheme('#fff', '#fff')}
                                    onClick={() => {
                                        onClose();
                                        login(name);
                                        dispatch.loginConfig.setLoginConfig(name);
                                    }}
                                    border="1px solid #00FFA1"
                                    _hover={{ bg: '#009961' }}
                                    borderRadius="10px"
                                    py="5px"
                                    px="20px"
                                    alignItems="center"
                                    justifyContent="center"
                                    text={
                                        <HStack>
                                            <Image
                                                src={process.env.PUBLIC_URL + '/assets/wallet/' + url}
                                                boxSize="30px"
                                                borderRadius="5px"
                                            />
                                            <Text as="span">{displayName}</Text>
                                        </HStack>
                                    }
                                />
                            );
                        })}
                    </HStack>
                </VStack>
            </Modal>
            {children}
        </ConnectContext.Provider>
    );
};

export default ConnectProvider;
