import { ChainId } from '../../types/mod';
import chains from '../chains';

export const LIQUIDITY_MANAGER_ADDRESS: TokenId2ContractAddress = chains.all.reduce<Record<number, string>>((memo, chain) => {
    const contracts = {
        [ChainId.ZkSyncEra]: '0x936c9A1B8f88BFDbd5066ad08e5d773BC82EB15F', //2023.03.27
        [ChainId.LineaTest]: '0xC6C7c2edF70A3245ad6051E93809162B9758ce08', // 2023.05.26
        [ChainId.Linea]: '0x1CB60033F61e4fc171c963f0d2d3F63Ece24319c', // 2023.07.17
    } as any;
    memo[chain.id] = contracts[chain.id] ?? '';
    return memo;
}, {});

export const LIMIT_ORDER_MANAGER_ADDRESSES: TokenId2ContractAddress = chains.all.reduce<Record<number, string>>((memo, chain) => {
    const contracts = {
        [ChainId.ZkSyncEra]: '0x8b9D7D609a83B2f69D2135786a7D230043AF7283', //2023.03.27
    } as any;
    memo[chain.id] = contracts[chain.id] ?? '';
    return memo;
}, {});

export const LIMIT_ORDER_WITH_SWAP_MANAGER_ADDRESSES: TokenId2ContractAddress = chains.all.reduce<Record<number, string>>((memo, chain) => {
    const contracts = {
        [ChainId.ZkSyncEra]: '0xC70daeFbEf10089231543CFaf9699Ff10124Ec22', //2023.03.28
    } as any;
    memo[chain.id] = contracts[chain.id] ?? '';
    return memo;
}, {});

export const SWAP_ADDRESS: TokenId2ContractAddress = chains.all.reduce<Record<number, string>>((memo, chain) => {
    const contracts = {
        [ChainId.ZkSyncEra]: '0xEbF9775748B7e25c3BEE5A673bb8785F324cF0D8', //ZJH,2023.06.29
        [ChainId.LineaTest]: '0x35BaD6A145D99889759180b4cB7f61a968BD170a', // 2023.06.30
        [ChainId.Linea]: '0xacd89bBFcB6574F5bA28f28f1Df8336D9E19A48A', // 2023.07.17
    } as any;
    memo[chain.id] = contracts[chain.id] ?? '';
    return memo;
}, {});

export const QUOTER_ADDRESS: TokenId2ContractAddress = chains.all.reduce<Record<number, string>>((memo, chain) => {
    const contracts = {
        [ChainId.ZkSyncEra]: '0x377EC7c9ae5a0787F384668788a1654249059dD6', // 2023.03.27
        [ChainId.LineaTest]: '0xF6FFe4f3FdC8BBb7F70FFD48e61f17D1e343dDfD', // 2023.05.26
        [ChainId.Linea]: '0xe6805638db944eA605e774e72c6F0D15Fb6a1347', // 2023.07.17

    } as any;
    memo[chain.id] = contracts[chain.id] ?? '';
    return memo;
}, {});

export const QUOTER_ADDRESS_LIMIT: TokenId2ContractAddress = chains.all.reduce<Record<number, string>>((memo, chain) => {
    const contracts = {
        [ChainId.ZkSyncEra]: '0x3EC82C07981D6D213DA9bd35A0ba4cd324feA438', //2023.03.27
        [ChainId.LineaTest]: '0x95c5F14106ab4d1dc0cFC9326225287c18c2d247', // 2023.05.26
        [ChainId.Linea]: '0xe4A0b241D8345d86FB140D40c87C5fbDd685B9dd', // 2023.07.17

    } as any;
    memo[chain.id] = contracts[chain.id] ?? null;
    return memo;
}, {});

export const BOX_ADDRESS: TokenId2ContractAddress = chains.all.reduce<Record<number, string>>((memo, chain) => {
    const contracts = {
        [ChainId.ZkSyncEra]: '0x7dEe7de9814ed6C1e20B3E4E2fA9b1B96E15FDe1', //2023.03.27
    } as any;
    memo[chain.id] = contracts[chain.id] ?? null;
    return memo;
}, {});

export enum QUOTER_TYPE {
    limit,
    full,
}

export const SWAP_ADDRESS_WITH_SOURCE = chains.all.reduce<Record<number, any>>((memo, chain) => {
    const contracts = {
        [ChainId.ZkSyncEra]: {
            'K61R3U7BM4': '0x7Fb9b1492e4D69Ee8840c00c1baBc0E988DeeE9D',
            '6xT2HD3hit': '0xeDd6fd68e3283D9855791bFb9B16C4D7E7591D07',
            '3EGeGLgkWg': '0x2ddb0F3B573e366642d0c6f66EB360B22f9F9E07',
            '18y5vp2zJh': '0xEbF9775748B7e25c3BEE5A673bb8785F324cF0D8',
            'tiIhNsj6J1': '0x253f388Fc2130fa7c7ea7e918e68B8DE3F03C970',
            'xsxRLZOiMV': '0xCBceb4549D1f6C19F8FFbdF567d3Cc812152F318',
            '9s3oueG5fc': '0x55ac279884Df044D450C9c5D872b5dA68C74E7A6',
            'qwSKXzLaA3': '0xd512AadF3D3a55E9E7F2D4A7989CD534E676bD47',
        },
        [ChainId.ZkSyncAlphaTest]: {
            'test_token': '0xdFA454b8BdA6B0b315852d178F00DE83D7739b2e',
        },
    } as any;
    memo[chain.id] = contracts[chain.id] ?? {};
    return memo;
}, {});
