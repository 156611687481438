import { Flex, VStack, Text } from '@chakra-ui/layout';
import React, { useEffect, useRef, useState } from 'react';
import Tabs from '../components/Tabs';
import SwapForm from './SwapForm';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { RootDispatch, RootState } from '../../../state/store';
import { SwapDetail } from './components/SwapDetail';
import LimitForm from '../Limit/LimitForm';
import { i_text_d } from '../../../style';
import { useWeb3WithDefault } from '../../../hooks/useWeb3WithDefault';
import { parallelCollect } from '../../../net/contractCall/parallel';
import { useTranslation } from 'react-i18next';
import { CandlestickData, IChartApi, ISeriesApi, Time } from 'lightweight-charts';
import { HStack, useBreakpointValue, Image } from '@chakra-ui/react';
import { getColorThemeSelector } from '../../../utils/funcs';
import { useColorMode } from '@chakra-ui/react';
import AdvanceChart from './AdvanceSwap/AdvanceChart';
import { iZiSwapKLinesRecordEnum, getIziSwapKLinesRecord } from '../../../net/iZUMi-endpoints/src/restful/api/analytics/izumiKlines';
import { dispatch } from 'd3';
import SwapSetting from './components/SwapSetting';
import { useRematchDispatch } from '../../../hooks/useRematchDispatch';
import Card from '../../../components/Card/Card';

export type AdvanceSwapProps = {
    tabValue: any;
    isSimpleMode: boolean;
    setIsSimpleMode: any;
};

const HintText: React.FC<{ text: string }> = ({ text }) => {
    return (
        <VStack h="100%" justifyContent="center">
            <Text className={i_text_d} variant="caption" lineHeight="24px" fontSize="16px">
                {text}
            </Text>
        </VStack>
    );
};

const AdvanceSwap: React.FC<AdvanceSwapProps> = (pros) => {
    const { isSimpleMode, setIsSimpleMode } = pros;
    const tabValue = pros.tabValue;
    const history = useHistory();
    const { t } = useTranslation();
    const { dispatch } = useRematchDispatch((dispatch: RootDispatch) => ({
        dispatch,
    }));
    const chartRef = useRef<IChartApi>(null);
    const candlesSeries = useRef<ISeriesApi<'Candlestick'>>(null);
    const [candlestickData, setCandlestickData] = useState([] as CandlestickData[]);
    const {
        tradeSwap: { swapForm, isSearchingX, isSearchingY },
    } = useSelector((state: RootState) => state);
    const isXlp1 = useBreakpointValue({ base: false, xxl: false, xlp1: true, '2xl': true });
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const chartTheme = colorTheme('#ffffff', '#1F1F1F');

    const [selectedInterval, setSelectedInterval] = useState('1h');
    const [chartLoading, setChartLoading] = useState(false);

    useEffect(() => {
        document.title = 'SURFI | The first native DEX Aggregator on Linea, focusing on Layer2 ecosystem.';
    }, [t]);

    const { chainId } = useWeb3WithDefault();

    const validChart = swapForm.tokenX.address && swapForm.tokenY.address;
    useEffect(() => {
        if (!validChart) {
            setCandlestickData([]);
            return;
        }

        const dayMode = selectedInterval === iZiSwapKLinesRecordEnum.DAY;
        const page_size = 300;
        const fetchTokenPrice = (address: string) =>
            getIziSwapKLinesRecord({
                chain_id: chainId,
                interval: dayMode ? iZiSwapKLinesRecordEnum.DAY : iZiSwapKLinesRecordEnum.HOUR_1,
                identity: address,
                order_by: '-time',
                page_size: page_size,
            });
        setChartLoading(true);
        parallelCollect(fetchTokenPrice(swapForm.tokenX.address), fetchTokenPrice(swapForm.tokenY.address)).then(
            ([priceHistoryX, priceHistoryY]) => {
                const priceX = priceHistoryX.data.is_success ? priceHistoryX.data.data : [];
                const priceY = priceHistoryY.data.is_success ? priceHistoryY.data.data : [];

                const togglePrice = swapForm.tokenX.address.toUpperCase() > swapForm.tokenY.address.toUpperCase();
                const timeToPriceY = Object.fromEntries(priceY.map((p) => [p.timestamp, p.close]));
                const timeToPriceYOpen = Object.fromEntries(priceY.map((p) => [p.timestamp, p.open]));
                const timeToPriceYHigh = Object.fromEntries(priceY.map((p) => [p.timestamp, p.high]));
                const timeToPriceYLow = Object.fromEntries(priceY.map((p) => [p.timestamp, p.low]));

                const priceHist: number[][] = [];
                const priceCandlestickData: CandlestickData[] = [];
                for (let index = 0; index < priceX.length; index++) {
                    const matchPriceY = timeToPriceY[priceX[index].timestamp];
                    const matchPriceYOpen = timeToPriceYOpen[priceX[index].timestamp];
                    const matchPriceYHigh = timeToPriceYHigh[priceX[index].timestamp];
                    const matchPriceYLow = timeToPriceYLow[priceX[index].timestamp];

                    const currentPriceX = priceX[index].close;
                    const currentPriceXOpen = priceX[index].open;
                    const currentPriceXHigh = priceX[index].high;
                    const currentPriceXLow = priceX[index].low;
                    if (!matchPriceY || !currentPriceX) {
                        continue;
                    }
                    const price = Number(currentPriceX) / Number(matchPriceY);
                    const priceOpen = Number(currentPriceXOpen) / Number(matchPriceYOpen);
                    const priceHigh = Number(currentPriceXHigh) / Number(matchPriceYHigh);
                    const priceLow = Number(currentPriceXLow) / Number(matchPriceYLow);
                    priceHist.push([priceX[index].timestamp * 1000, togglePrice ? 1 / price : price]);

                    priceCandlestickData.push({
                        time: priceX[index].timestamp as Time,
                        open: togglePrice ? 1 / priceOpen : priceOpen,
                        high: togglePrice ? 1 / priceLow : priceHigh,
                        low: togglePrice ? 1 / priceHigh : priceLow,
                        close: togglePrice ? 1 / price : price,
                    });
                }
                setCandlestickData(priceCandlestickData.sort((a, b) => Number(a.time) - Number(b.time)));
                setChartLoading(false);
            }
        );
    }, [chainId, swapForm.tokenX.address, swapForm.tokenY.address, selectedInterval, validChart]);

    const handleChangeTab = (value: string) => {
        if (value === 'Swap') {
            history.push('/swap', { mode: 'advance' });
        }
        if (value === 'Limit Order') {
            history.push('/limit', { mode: 'advance' });
        }
    };

    return (
        <>
            <HStack justifyContent="space-between">
                <HStack spacing="10px">
                    <Text
                        variant="caption"
                        className={i_text_d}
                        fontFamily="Montserrat-SemiBold"
                        fontSize="20px !important"
                        color={colorTheme('#000000', '#00F69B')}
                    >
                        Swap
                    </Text>
                    <Image
                        boxSize="18px"
                        src="/assets/swap/change.png"
                        fallbackSrc="/assets/swap/change.png"
                        cursor="pointer"
                        onClick={() => {}}
                    ></Image>
                </HStack>
                <HStack spacing="20px">
                    <SwapSetting
                        w="18px"
                        h="18px"
                        exclusiveLiquidity={swapForm.exclusiveLiquidity}
                        slippage={swapForm.slippagePercent}
                        maxDelay={swapForm.maxDelay}
                        quoterType={swapForm.quoterType}
                        handleSetSlippage={dispatch.tradeSwap.setSwapFormSlippagePercent}
                        handleSetMaxDelay={dispatch.tradeSwap.setSwapFormMaxDelay}
                        handleSetQuoterType={dispatch.tradeSwap.setSwapFormQuoterType}
                        handleSetExclusive={dispatch.tradeSwap.setSwapFormExclusiveLiquidity}
                    />
                    <Image
                        boxSize="18px"
                        src={colorTheme('/assets/swap/changeMode.png', '/assets/swap/darkChangeMode.png')}
                        fallbackSrc={colorTheme('/assets/swap/changeMode.png', '/assets/swap/darkChangeMode.png')}
                        cursor="pointer"
                        onClick={() => {
                            setIsSimpleMode(!isSimpleMode);
                        }}
                    ></Image>
                </HStack>
            </HStack>

            <Flex justifyContent="center">
                <HStack alignItems="start">
                    <VStack>
                        {!validChart ? (
                            <Card w={{ base: '523px', xlp1: '533px' }} h="432px">
                                <HintText text="No related Chart" />
                            </Card>
                        ) : (
                            <AdvanceChart
                                chartWidth={isXlp1 ? 533 : 523}
                                height={432}
                                chartRef={chartRef}
                                series={candlesSeries}
                                data={candlestickData}
                                themeColor={chartTheme}
                                tokenA={swapForm.tokenX}
                                tokenB={swapForm.tokenY}
                                selectedInterval={selectedInterval}
                                setSelectedInterval={setSelectedInterval}
                                showInterval={[iZiSwapKLinesRecordEnum.HOUR_1, iZiSwapKLinesRecordEnum.DAY]}
                                chartLoading={chartLoading}
                            ></AdvanceChart>
                        )}
                        <Card w={{ base: '100%', sm: '578px' }} h="145px" px="10px" justifyContent="center">
                            {swapForm.swapPath && !swapForm.noSufficientLiquidity ? (
                                <SwapDetail swapForm={swapForm} ready={!isSearchingX && !isSearchingY}></SwapDetail>
                            ) : (
                                <HintText text={t('Please select the pair you want to check and trade') + '.'} />
                            )}
                        </Card>
                    </VStack>

                    <SwapForm setIsSimpleMode={setIsSimpleMode} />
                </HStack>
            </Flex>
        </>
    );
};

export default AdvanceSwap;
