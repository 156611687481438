import { BoxProps, Stack, Text, Image, HStack } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { links } from '../../config/links';
import { i_h4, i_text_copy, i_text_d } from '../../style';

export const Footer: React.FC<BoxProps> = (props) => {
    const { ...rest } = props;
    const history = useHistory();

    return (
        <HStack w="100%" justifyContent="space-between" px="54px" {...rest}>
            <Stack spacing="20px">
                <Image
                    src={process.env.PUBLIC_URL + '/assets/home/iziLogo/darkLogo.svg'}
                    width="104px"
                    cursor="pointer"
                    onClick={() => {
                        window.open(links.surfi, '_self');
                    }}
                />
                <Text className={i_text_d}>Trading is Surfing. Enjoy the Crypto Waves.</Text>
                <Text className={i_text_d}>The first native DEX Aggregator on Linea, focusing on Layer2 ecosystem.</Text>
            </Stack>

            <HStack spacing="110px" alignItems="start">
                <Stack>
                    <Text className={i_h4} fontSize="16px">
                        Products
                    </Text>
                    <Text
                        className={i_text_copy}
                        fontSize="14px"
                        cursor="pointer"
                        onClick={() => {
                            history.push('/');
                        }}
                    >
                        Trade
                    </Text>
                </Stack>
                <Stack>
                    <Text className={i_h4} fontSize="16px">
                        Community
                    </Text>
                    <Text
                        className={i_text_copy}
                        fontSize="14px"
                        cursor="pointer"
                        onClick={() => {
                            window.open(links.twitter);
                        }}
                    >
                        Twitter
                    </Text>
                    <Text
                        className={i_text_copy}
                        fontSize="14px"
                        cursor="pointer"
                        onClick={() => {
                            window.open(links.medium);
                        }}
                    >
                        Medium
                    </Text>
                </Stack>
                <Stack>
                    <Text className={i_h4} fontSize="16px">
                        Support
                    </Text>
                    <Text
                        className={i_text_copy}
                        fontSize="14px"
                        cursor="pointer"
                        onClick={() => {
                            window.open(links.BrandKit);
                        }}
                    >
                        Brand Kit
                    </Text>
                </Stack>
            </HStack>
        </HStack>
    );
};
