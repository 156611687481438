import { tokenSymbol2token } from '../../../../../config/tokens'
import { ChainId, TokenSymbol } from '../../../../../types/mod'

export const SupportFeeTiers = {
    //mainnet
    [ChainId.BSC]: [0.01, 0.04, 0.2, 1], // ${fee}%
    [ChainId.Aurora]: [0.01, 0.04, 0.2, 1],
    [ChainId.ETC]: [0.01, 0.04, 0.2, 1],
    [ChainId.Arbitrum]: [0.01, 0.04, 0.2, 1],
    [ChainId.Matic]: [0.01, 0.04, 0.2, 1],
    [ChainId.Cronos]: [0.01, 0.04, 0.2, 1],
    [ChainId.Icplaza]: [0.01, 0.04, 0.2, 1],
    [ChainId.ConfluxESpace]: [0.01, 0.04, 0.2, 1],
    [ChainId.Meter]: [0.01, 0.04, 0.2, 1],
    [ChainId.ZkSyncEra]: [0.01, 0.04, 0.2, 1],
    [ChainId.Linea]: [0.05, 0.3, 1],
    // test
    [ChainId.BSCTestnet]: [0.01, 0.04, 0.2, 1],
    [ChainId.AuroraTestnet]: [0.01, 0.04, 0.2, 1],
    [ChainId.MantleTest]: [0.01, 0.04, 0.2, 1],
    [ChainId.ZkSyncAlphaTest]: [0.01, 0.04, 0.2, 1],
    [ChainId.ScrollTestL2]: [0.01, 0.04, 0.2, 1],
    [ChainId.LineaTest]: [0.04, 0.2, 1],
} as Record<ChainId, FeeTier[]>;

export const SUPPORTED_001_POOL = {
    //mainnet
    [ChainId.BSC]: [
        {
            tokenA: tokenSymbol2token(TokenSymbol.IUSD, ChainId.BSC),
            tokenB: tokenSymbol2token(TokenSymbol.USDT, ChainId.BSC),
        },
        {
            tokenA: tokenSymbol2token(TokenSymbol.IUSD, ChainId.BSC),
            tokenB: tokenSymbol2token(TokenSymbol.BUSD, ChainId.BSC),
        },
    ],
    [ChainId.Arbitrum]: [],
    [ChainId.Matic]: [],
    [ChainId.Aurora]: [],
    [ChainId.ETC]: [],
    [ChainId.Meter]: [],
    [ChainId.Icplaza]: [],
    [ChainId.Cronos]:[],
    [ChainId.ZkSyncEra]:[],
    //test
    [ChainId.BSCTestnet]: [],
    [ChainId.AuroraTestnet]: [],
    [ChainId.MantleTest]: [],
    [ChainId.ZkSyncAlphaTest]: [],
    [ChainId.ScrollTestL2]: [],
    [ChainId.LineaTest]: [],
} as any;


export const MULTIHOP_MID_TOKEN_SYMBOL = {
    [ChainId.BSC]: [TokenSymbol.BUSD, TokenSymbol.BNB, TokenSymbol.USDT, TokenSymbol.USDC, TokenSymbol.IUSD],
    [ChainId.Aurora]: [TokenSymbol.ETH, TokenSymbol.USDC, TokenSymbol.USDT, TokenSymbol.USN],
    [ChainId.Arbitrum]: [TokenSymbol.USDC, TokenSymbol.USDT, TokenSymbol.ETH],
    [ChainId.ConfluxESpace]: [TokenSymbol.USDT],
    [ChainId.ETC]: [TokenSymbol.ETC, TokenSymbol.IUSD],
    [ChainId.ZkSyncEra]: [TokenSymbol.USDC, TokenSymbol.ETH,TokenSymbol.IUSD],
    [ChainId.BSCTestnet]: [TokenSymbol.BNB, TokenSymbol.USDT, TokenSymbol.USDC, TokenSymbol.IUSD],
    [ChainId.AuroraTestnet]: [TokenSymbol.ETH, TokenSymbol.USDC, TokenSymbol.USDT, TokenSymbol.USN],
    [ChainId.MantleTest]: [TokenSymbol.USDT],
    [ChainId.LineaTest]: [TokenSymbol.USDT, TokenSymbol.USDC],
} as Record<ChainId, TokenSymbol[]>;


export const STABLE_COIN_SYMBOL = {
    [ChainId.BSC]: [TokenSymbol.BUSD, TokenSymbol.USDT, TokenSymbol.USDC, TokenSymbol.IUSD],
    [ChainId.Aurora]: [TokenSymbol.USDT, TokenSymbol.USDC, TokenSymbol.USN],
    [ChainId.Arbitrum]: [TokenSymbol.USDC, TokenSymbol.USDT],
    [ChainId.ConfluxESpace]: [TokenSymbol.USDT],
    [ChainId.ETC]: [TokenSymbol.IUSD],
    [ChainId.ZkSyncEra]: [TokenSymbol.IUSD, TokenSymbol.USDC],
    [ChainId.BSCTestnet]: [TokenSymbol.USDT, TokenSymbol.USDC, TokenSymbol.IUSD],
    [ChainId.AuroraTestnet]: [TokenSymbol.USDT, TokenSymbol.USDC, TokenSymbol.USN],
    [ChainId.MantleTest]: [TokenSymbol.USDT],
} as Record<ChainId, TokenSymbol[]>;

export const LOW_CHANCE_POOL = {
    // mainnet
    [ChainId.ZkSyncEra]: [
        [TokenSymbol.USDC, TokenSymbol.WETH, 0.04],
        [TokenSymbol.IZI, TokenSymbol.WETH, 0.04], 
        [TokenSymbol.IZI, TokenSymbol.USDC, 0.04],
    ]
    // testnet
} as any;

export const LOW_CHANGE  = 5;  // 0.2