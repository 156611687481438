import { ChainId, TokenSymbol } from '../../../../../types/mod';

export const SPACEFI_FACTORY_ADDRESS = {
    [ChainId.ZkSyncEra]: '0x0700Fb51560CfC8F896B2c812499D17c5B0bF6A7',
} as Record<ChainId, string>

export const SPACEFI_SWAP_ROUTER_ADDRESS = {
    [ChainId.ZkSyncEra]: '0xbE7D1FD1f6748bbDefC4fbaCafBb11C6Fc506d1d',
} as Record<ChainId, string>

export const SPACEFI_FEE_RATE = {
    [ChainId.ZkSyncEra]: 0.003
} as Record<ChainId, number>


export const SPACEFI_MULTIHOP_MID_TOKEN_SYMBOL = {
    [ChainId.ZkSyncEra]: [TokenSymbol.ETH, TokenSymbol.USDC],
} as Record<ChainId, TokenSymbol[]>


export const SPACEFI_TOKEN_BLACK_LIST = [TokenSymbol.COCA]