import { Divider, HStack, Stack, Text, Image, useColorMode } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import useIsMobile from '../../../../hooks/useIsMobile';
import Card from '../../../../iZUMi-UI-toolkit/src/components/Card/Card';
import { i_text_copy } from '../../../../style';
import { getColorThemeSelector } from '../../../../utils/funcs';

const Rules: React.FC = () => {
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const isMobile = useIsMobile();
    const { t } = useTranslation();

    return (
        <Card
            w={{ base: '100%', sm: '441px', xxl: '496px' }}
            h={{ base: 'unset', sm: '549px' }}
            bg={colorTheme('#FDFEFF', '#161322')}
            px={{ base: '2px', sm: '18px' }}
            py="10px"
        >
            <Stack px={{ base: '15px', sm: '18px' }} mt="16px" spacing="2px">
                <Text className={i_text_copy} fontSize="20px" fontWeight="600" color={colorTheme('#24193B', '#F5F5F5')} lineHeight="unset">
                    {t('Rules')}
                </Text>
                <Text className={i_text_copy} color="#6A5E86">
                    {t('Use the iZiSwap on zkSync, Earn the iPoints')}
                </Text>
            </Stack>
            <Divider
                w={{ base: 'unset', sm: '381px', xxl: '439px' }}
                variant="dashed"
                alignSelf="center"
                my="12px"
                borderColor={colorTheme('#CFCCDE', 'rgba(207, 204, 222, 0.28)')}
            ></Divider>
            <Stack px={{ base: '15px', sm: '18px' }}>
                <HStack spacing={{ base: '5px', sm: '16px' }} alignItems="start">
                    {isMobile ? (
                        <Stack
                            minW="75px"
                            className={i_text_copy}
                            fontWeight="600"
                            color={colorTheme('#291A4B', 'rgba(144, 117, 207, 0.53)')}
                            spacing="4px"
                        >
                            <Text>{t('Swap Value')}</Text>
                            <Text>{t('in a week')}</Text>
                        </Stack>
                    ) : (
                        <Text w="75px" className={i_text_copy} fontWeight="600" color={colorTheme('#291A4B', 'rgba(144, 117, 207, 0.53)')}>
                            {t('Swap Value in a week')}
                        </Text>
                    )}

                    <Stack flex="1">
                        <HStack>
                            <Text className={i_text_copy} color={colorTheme('#352360', '#CCBFFF')}>
                                $50 +
                            </Text>
                            <HStack flex="1" spacing="0px" px="10px">
                                <Divider
                                    border={colorTheme('1px solid #E8E6EF', '1px solid rgba(132, 107, 255, 0.22)')}
                                    mr="-3px"
                                ></Divider>
                                <Image
                                    w="5px"
                                    src={colorTheme('/assets/iPoints/rules/right.svg', '/assets/iPoints/rules/darkRight.svg')}
                                ></Image>
                            </HStack>
                            <HStack spacing="5px" ml="auto !important">
                                <Image
                                    boxSize="11px"
                                    src={colorTheme('/assets/iPoints/blueGift.svg', '/assets/iPoints/darkBlueGift.svg')}
                                ></Image>
                                <Text
                                    w={{ base: 'unset', sm: '25px' }}
                                    className={i_text_copy}
                                    fontWeight="600"
                                    textAlign="end"
                                    color={colorTheme('#291A4B', '#C9BDE5')}
                                >
                                    +10
                                </Text>
                            </HStack>
                        </HStack>

                        <HStack>
                            <Text className={i_text_copy} color={colorTheme('#352360', '#CCBFFF')}>
                                $500 +
                            </Text>
                            <HStack flex="1" spacing="0px" px="10px">
                                <Divider
                                    border={colorTheme('1px solid #E8E6EF', '1px solid rgba(132, 107, 255, 0.22)')}
                                    mr="-3px"
                                ></Divider>
                                <Image
                                    w="5px"
                                    src={colorTheme('/assets/iPoints/rules/right.svg', '/assets/iPoints/rules/darkRight.svg')}
                                ></Image>
                            </HStack>
                            <HStack spacing="5px" ml="auto !important">
                                <Image
                                    boxSize="11px"
                                    src={colorTheme('/assets/iPoints/blueGift.svg', '/assets/iPoints/darkBlueGift.svg')}
                                ></Image>
                                <Text
                                    w={{ base: 'unset', sm: '25px' }}
                                    className={i_text_copy}
                                    fontWeight="600"
                                    textAlign="end"
                                    color={colorTheme('#291A4B', '#C9BDE5')}
                                >
                                    +20
                                </Text>
                            </HStack>
                        </HStack>
                        <HStack>
                            <Text className={i_text_copy} color={colorTheme('#352360', '#CCBFFF')}>
                                $50 iZi +
                            </Text>
                            <HStack flex="1" spacing="0px" px="10px">
                                <Divider
                                    border={colorTheme('1px solid #E8E6EF', '1px solid rgba(132, 107, 255, 0.22)')}
                                    mr="-3px"
                                ></Divider>
                                <Image
                                    w="5px"
                                    src={colorTheme('/assets/iPoints/rules/right.svg', '/assets/iPoints/rules/darkRight.svg')}
                                ></Image>
                            </HStack>
                            <HStack spacing="5px" ml="auto !important">
                                <Image
                                    boxSize="11px"
                                    src={colorTheme('/assets/iPoints/blueGift.svg', '/assets/iPoints/darkBlueGift.svg')}
                                ></Image>
                                <Text
                                    w={{ base: 'unset', sm: '25px' }}
                                    className={i_text_copy}
                                    fontWeight="600"
                                    textAlign="end"
                                    color={colorTheme('#291A4B', '#C9BDE5')}
                                >
                                    +30
                                </Text>
                            </HStack>
                        </HStack>
                        <HStack>
                            <Text className={i_text_copy} color={colorTheme('#352360', '#CCBFFF')}>
                                $5000 +
                            </Text>
                            <HStack flex="1" spacing="0px" px="10px">
                                <Divider
                                    border={colorTheme('1px solid #E8E6EF', '1px solid rgba(132, 107, 255, 0.22)')}
                                    mr="-3px"
                                ></Divider>
                                <Image
                                    w="5px"
                                    src={colorTheme('/assets/iPoints/rules/right.svg', '/assets/iPoints/rules/darkRight.svg')}
                                ></Image>
                            </HStack>
                            <HStack spacing="5px" ml="auto !important">
                                <Image
                                    boxSize="11px"
                                    src={colorTheme('/assets/iPoints/blueGift.svg', '/assets/iPoints/darkBlueGift.svg')}
                                ></Image>
                                <Text
                                    w={{ base: 'unset', sm: '25px' }}
                                    className={i_text_copy}
                                    fontWeight="600"
                                    textAlign="end"
                                    color={colorTheme('#291A4B', '#C9BDE5')}
                                >
                                    +50
                                </Text>
                            </HStack>
                        </HStack>
                    </Stack>
                </HStack>
            </Stack>
            <Divider
                w={{ base: 'unset', sm: '381px', xxl: '439px' }}
                variant="dashed"
                alignSelf="center"
                my="12px"
                borderColor={colorTheme('#CFCCDE', 'rgba(207, 204, 222, 0.28)')}
            ></Divider>
            <Stack px={{ base: '15px', sm: '18px' }}>
                <HStack spacing={{ base: '19px', sm: '27px', xxl: '16px' }} alignItems="start">
                    {isMobile ? (
                        <Stack
                            minW="60px"
                            className={i_text_copy}
                            fontWeight="600"
                            color={colorTheme('#291A4B', 'rgba(144, 117, 207, 0.53)')}
                            spacing="4px"
                        >
                            <Text>{t('Hold LPS')}</Text>
                            <Text>{t('in a week')}</Text>
                        </Stack>
                    ) : (
                        <Text w="60px" className={i_text_copy} fontWeight="600" color={colorTheme('#291A4B', 'rgba(144, 117, 207, 0.53)')}>
                            {t('Hold LPS in a week')}
                        </Text>
                    )}

                    <Stack flex="1">
                        <HStack>
                            <Stack spacing="3px">
                                <Text className={i_text_copy} color={colorTheme('#6A5E86', 'rgba(203, 181, 255, 0.75)')}>
                                    {t('LP value')}
                                    <Text as="span" color={colorTheme('#3B2276', '#CCBFFF')}>
                                        {' '}
                                        $ 0.5k-5k
                                    </Text>
                                </Text>
                                <Text className={i_text_copy} color={colorTheme('#A093BE', 'rgba(160, 147, 190, 0.75)')}>
                                    {t('Stake at least 3 days')}
                                </Text>
                            </Stack>
                            <HStack
                                flex="1"
                                spacing="0px"
                                px={{ base: '6px', sm: '10px' }}
                                ml={{ base: '0px !important', sm: '8px !important' }}
                            >
                                <Divider
                                    minW="20px"
                                    border={colorTheme('1px solid #E8E6EF', '1px solid rgba(132, 107, 255, 0.22)')}
                                    mr="-3px"
                                ></Divider>
                                <Image
                                    w="5px"
                                    src={colorTheme('/assets/iPoints/rules/right.svg', '/assets/iPoints/rules/darkRight.svg')}
                                ></Image>
                            </HStack>
                            <HStack spacing="5px" ml="auto !important">
                                <Image
                                    boxSize="11px"
                                    src={colorTheme('/assets/iPoints/blueGift.svg', '/assets/iPoints/darkBlueGift.svg')}
                                ></Image>
                                <Text
                                    w={{ base: 'unset', sm: '25px' }}
                                    className={i_text_copy}
                                    fontWeight="600"
                                    textAlign="end"
                                    color={colorTheme('#291A4B', '#C9BDE5')}
                                >
                                    +30
                                </Text>
                            </HStack>
                        </HStack>
                        <HStack>
                            <Stack spacing="3px">
                                <Text className={i_text_copy} color={colorTheme('#6A5E86', 'rgba(203, 181, 255, 0.75)')}>
                                    {t('LP value')}
                                    <Text as="span" color={colorTheme('#3B2276', '#CCBFFF')}>
                                        {' '}
                                        $ 0.5k-5k
                                    </Text>
                                </Text>
                                <Text className={i_text_copy} color={colorTheme('#A093BE', 'rgba(160, 147, 190, 0.75)')}>
                                    {t('Stake at least 5 days')}
                                </Text>
                            </Stack>
                            <HStack
                                flex="1"
                                spacing="0px"
                                px={{ base: '6px', sm: '10px' }}
                                ml={{ base: '0px !important', sm: '8px !important' }}
                            >
                                <Divider
                                    minW="20px"
                                    border={colorTheme('1px solid #E8E6EF', '1px solid rgba(132, 107, 255, 0.22)')}
                                    mr="-3px"
                                ></Divider>
                                <Image
                                    w="5px"
                                    src={colorTheme('/assets/iPoints/rules/right.svg', '/assets/iPoints/rules/darkRight.svg')}
                                ></Image>
                            </HStack>
                            <HStack spacing="5px" ml="auto !important">
                                <Image
                                    boxSize="11px"
                                    src={colorTheme('/assets/iPoints/blueGift.svg', '/assets/iPoints/darkBlueGift.svg')}
                                ></Image>
                                <Text
                                    w={{ base: 'unset', sm: '25px' }}
                                    className={i_text_copy}
                                    fontWeight="600"
                                    textAlign="end"
                                    color={colorTheme('#291A4B', '#C9BDE5')}
                                >
                                    +50
                                </Text>
                            </HStack>
                        </HStack>
                        <HStack>
                            <Stack spacing="3px">
                                <Text className={i_text_copy} color={colorTheme('#6A5E86', 'rgba(203, 181, 255, 0.75)')}>
                                    {t('LP value')}
                                    <Text as="span" color={colorTheme('#3B2276', '#CCBFFF')}>
                                        {' '}
                                        $ 5k
                                    </Text>
                                </Text>
                                <Text className={i_text_copy} color={colorTheme('#A093BE', 'rgba(160, 147, 190, 0.75)')}>
                                    {t('Stake at least 3 days')}
                                </Text>
                            </Stack>
                            <HStack
                                flex="1"
                                spacing="0px"
                                px={{ base: '6px', sm: '10px' }}
                                ml={{ base: '0px !important', sm: '8px !important' }}
                            >
                                <Divider
                                    minW="20px"
                                    border={colorTheme('1px solid #E8E6EF', '1px solid rgba(132, 107, 255, 0.22)')}
                                    mr="-3px"
                                ></Divider>
                                <Image
                                    w="5px"
                                    src={colorTheme('/assets/iPoints/rules/right.svg', '/assets/iPoints/rules/darkRight.svg')}
                                ></Image>
                            </HStack>
                            <HStack spacing="5px" ml="auto !important">
                                <Image
                                    boxSize="11px"
                                    src={colorTheme('/assets/iPoints/blueGift.svg', '/assets/iPoints/darkBlueGift.svg')}
                                ></Image>
                                <Text
                                    w={{ base: 'unset', sm: '25px' }}
                                    className={i_text_copy}
                                    fontWeight="600"
                                    textAlign="end"
                                    color={colorTheme('#291A4B', '#C9BDE5')}
                                >
                                    +60
                                </Text>
                            </HStack>
                        </HStack>{' '}
                        <HStack>
                            <Stack spacing="3px">
                                <Text className={i_text_copy} color={colorTheme('#6A5E86', 'rgba(203, 181, 255, 0.75)')}>
                                    {t('LP value')}
                                    <Text as="span" color={colorTheme('#3B2276', '#CCBFFF')}>
                                        {' '}
                                        $ 5k
                                    </Text>
                                </Text>
                                <Text className={i_text_copy} color={colorTheme('#A093BE', 'rgba(160, 147, 190, 0.75)')}>
                                    {t('Stake at least 5 days')}
                                </Text>
                            </Stack>
                            <HStack
                                flex="1"
                                spacing="0px"
                                px={{ base: '4px', sm: '10px' }}
                                ml={{ base: '0px !important', sm: '8px !important' }}
                            >
                                <Divider
                                    minW="20px"
                                    border={colorTheme('1px solid #E8E6EF', '1px solid rgba(132, 107, 255, 0.22)')}
                                    mr="-3px"
                                ></Divider>
                                <Image
                                    w="5px"
                                    src={colorTheme('/assets/iPoints/rules/right.svg', '/assets/iPoints/rules/darkRight.svg')}
                                ></Image>
                            </HStack>
                            <HStack spacing="5px" ml="auto !important">
                                <Image
                                    boxSize="11px"
                                    src={colorTheme('/assets/iPoints/blueGift.svg', '/assets/iPoints/darkBlueGift.svg')}
                                ></Image>
                                <Text
                                    w={{ base: 'unset', sm: '29px' }}
                                    className={i_text_copy}
                                    fontWeight="600"
                                    textAlign="end"
                                    color={colorTheme('#291A4B', '#C9BDE5')}
                                >
                                    +100
                                </Text>
                            </HStack>
                        </HStack>
                    </Stack>
                </HStack>
            </Stack>
            <Divider
                w={{ base: 'unset', sm: '381px', xxl: '439px' }}
                variant="dashed"
                alignSelf="center"
                my="12px"
                borderColor="#CFCCDE"
            ></Divider>
            <Stack
                w={{ base: 'unset', sm: '100%' }}
                h="100%"
                bg={colorTheme('#FFFDF1', '#221F1E')}
                py="10px"
                px="15px"
                mx={{ base: '15px', sm: '0px' }}
                mt="auto"
                mb={{ base: '12px', sm: '12px' }}
                justifyContent="center"
            >
                <HStack>
                    <Image boxSize="11px" src="/assets/iPoints/rules/notice.svg"></Image>
                    <Text className={i_text_copy} color="#EBC541">
                        {t('Notice')}
                    </Text>
                </HStack>
                <Text className={i_text_copy} color={colorTheme('#B49A3F', 'rgba(196, 153, 1, 0.62)')}>
                    {t(
                        'We will calculate from every 0:00 AM Monday until 12:00 PM Sunday(UTC+8). This is a weekly campaign, and iPoints will be collected once a week.',
                        { nsSeparator: false }
                    )}
                </Text>
            </Stack>
        </Card>
    );
};
export default Rules;
