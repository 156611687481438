import React from 'react';
import { ImageProps, Image, useColorMode } from '@chakra-ui/react';
import { getColorThemeSelector } from '../../iZUMi-UI-toolkit/src/utils/funcs';

type CloseButtonProps = {
    onClose: () => void;
} & ImageProps;

const CloseButton: React.FC<CloseButtonProps> = (props) => {
    const { onClose, ...rest } = props;
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);

    return (
        <Image
            onClick={onClose}
            cursor="pointer"
            w="30px"
            h="30px"
            p="8px"
            src={colorTheme(process.env.PUBLIC_URL + '/assets/trade/lightCloseButton.png', '/assets/trade/darkCloseButton.png')}
            fallbackSrc={colorTheme(process.env.PUBLIC_URL + '/assets/trade/lightCloseButton.png', '/assets/trade/darkCloseButton.png')}
            {...rest}
        ></Image>
    );
};

export default CloseButton;
