import { SearchIcon } from '@chakra-ui/icons';
import { i_text_copy_bold } from '../../../style';
import { Input, InputGroup, InputRightElement, InputProps, useColorMode, Image } from '@chakra-ui/react';
import { getColorThemeSelector } from '../../../utils/funcs';

type SearchInputProps = {
    placeholder: string | any;
    onSearchKeyChange: any;
    value: any;
    size?: string;
} & InputProps;

export const SearchInput: React.FC<SearchInputProps> = (props) => {
    const { placeholder, size, onSearchKeyChange, value, ...rest } = props;
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);

    return (
        <InputGroup border="1px solid #707070" borderRadius="5px" {...rest}>
            <Input
                h="40px"
                variant="filled"
                placeholder={placeholder}
                size={size ?? 'lg'}
                className={i_text_copy_bold}
                fontSize="12px"
                onChange={(e: any) => onSearchKeyChange(e)}
                value={value || ''}
                bg={colorTheme('#F9F9FB', '#0F0F0F')}
                _focus={{ borderColor: colorTheme('', '#00FFA1') }}
            />
            <InputRightElement h="100%">
                <Image boxSize="18px" src="/assets/search/search.png"></Image>
                {/* <SearchIcon boxSize={4} /> */}
            </InputRightElement>
        </InputGroup>
    );
};
