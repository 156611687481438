import { ChevronDownIcon, SearchIcon } from '@chakra-ui/icons';
import {
    Button,
    FormControl,
    FormLabel,
    HStack,
    Input,
    InputGroup,
    InputRightElement,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Stack,
    Switch,
    Box,
    useColorMode,
    Flex,
    InputLeftElement,
} from '@chakra-ui/react';
import { i_text_copy, i_text_copy_bold } from '../../../style';
import { useCallback } from 'react';
import { useWeb3React } from '@web3-react/core';
import { getColorThemeSelector } from '../../../utils/funcs';
import { Filter } from '../Gallery/components/Filter';
import useIsMobile from '../../../hooks/useIsMobile';
import NetworkSelect from '../../../components/Select/NetworkSelect/NetworkSelect';
import CustomButton from '../../../iZUMi-UI-toolkit/src/components/Buttons/CustomButton/CustomButton';
import { useTranslation } from 'react-i18next';
import useIsPC from '../../../hooks/useIsPC';

type ControlsProps = {
    farm: any;
    dispatch: any;
};

const Controls: React.FC<ControlsProps> = (props) => {
    const { farm, dispatch } = props;
    const { t } = useTranslation();
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const { account } = useWeb3React();
    const isMobile = useIsMobile();
    const isPC = useIsPC();
    const onOnlyStakedChange = useCallback(
        (e: any) => {
            const farmControl = { ...farm.farmControl };
            farmControl.stakedOnly = e.target.checked;
            dispatch.setFarmControl(farmControl);
        },
        [farm, dispatch]
    );

    const onSortByChange = useCallback(
        (e: any) => {
            const farmControl = { ...farm.farmControl };
            farmControl.sortBy = e;
            dispatch.setFarmControl(farmControl);
        },
        [farm, dispatch]
    );

    const onSearchKeyChange = useCallback(
        (e: any) => {
            console.log(e.target.value);
            const farmControl = { ...farm.farmControl };
            farmControl.searchKey = e.target.value;
            dispatch.setFarmControl(farmControl);
        },
        [farm, dispatch]
    );

    const onTypeChange = useCallback(
        (e: any) => {
            console.log(e);
            const farmControl = { ...farm.farmControl };
            farmControl.type = e;
            dispatch.setFarmControl(farmControl);
        },
        [farm, dispatch]
    );
    const LiveEndBlock = () => (
        <Stack
            w={{ base: '100%', xl: 'unset' }}
            justifyContent={{ base: 'end', xl: 'unset' }}
            direction="row"
            flexGrow={{ base: 0, xl: 0, xxl: 1 }}
            alignItems="center"
            ml={{ base: 'auto', xl: 'unset' }}
        >
            <CustomButton
                variant="none"
                text={t('Live')}
                pt="0px"
                pl={{ base: '0px', xxl: '40px' }}
                className={i_text_copy_bold}
                fontSize="16px"
                selected={farm.farmControl.type === 'live'}
                onClick={() => {
                    onTypeChange('live');
                }}
            />

            <Box h="50%" w="2px" bg={colorTheme('tertiary.200', 'tertiary.400')} />

            <CustomButton
                variant="none"
                ml="0 !important"
                pt="0px"
                pl="10px"
                text={t('Ended')}
                className={i_text_copy_bold}
                fontSize="16px"
                selected={farm.farmControl.type === 'ended'}
                onClick={() => {
                    onTypeChange('ended');
                }}
            />
        </Stack>
    );
    const StakeBlock = () => (
        <FormControl w={{ base: '140px', sm: 'unset' }} display="flex" alignItems="center" ml={{ base: 'auto !important', sm: 'unset' }}>
            <FormLabel
                w="100%"
                className={i_text_copy}
                fontSize="12px"
                fontWeight="500"
                ml={{ base: 'unset', md: '0px', xxl: '20px' }}
                mr={{ base: '5px', xxl: '12px' }}
                pt="6px"
            >
                {t('Staked Only')}
            </FormLabel>
            <Switch isChecked={farm.farmControl.stakedOnly} onChange={(e: any) => onOnlyStakedChange(e)} size="lg"></Switch>
        </FormControl>
    );
    const SortByBlock = () => (
        <FormControl display="flex" alignItems="center" w="unset">
            <FormLabel className={i_text_copy} fontSize="12px" fontWeight="500" pt="6px">
                {t('Sort by')}
            </FormLabel>
            <Menu>
                {() => (
                    <>
                        <MenuButton
                            as={Button}
                            rightIcon={<ChevronDownIcon />}
                            className={i_text_copy_bold}
                            fontSize="14px"
                            w={{ base: '140px', sm: '168px' }}
                        >
                            {farm.farmControl.sortBy || t('Hot')}
                        </MenuButton>
                        <MenuList className={i_text_copy}>
                            <MenuItem onClick={() => onSortByChange('APR')}>{t('APR')} </MenuItem>
                            <MenuItem onClick={() => onSortByChange('Liquidity')}>{t('Liquidity')} </MenuItem>
                            <MenuItem onClick={() => onSortByChange('Hot')}>{t('Hot')} </MenuItem>
                        </MenuList>
                    </>
                )}
            </Menu>
        </FormControl>
    );

    return isMobile ? (
        <HStack
            w="100%"
            alignItems="start"
            pt="16px"
            pb="8px"
            justifyContent={{ base: 'space-between' }}
            spacing={{ base: '10px', xxl: '20px' }}
        >
            <InputGroup h="35px" flex="1">
                <InputLeftElement boxSize="20px" mt="5px" ml="10px">
                    <SearchIcon boxSize={5} />
                </InputLeftElement>
                <Input
                    variant="filled"
                    h="30px"
                    placeholder={t('Search Tokens or Pools')}
                    size="lg"
                    className={i_text_copy_bold}
                    fontSize="12px"
                    onChange={(e: any) => onSearchKeyChange(e)}
                    value={farm.farmControl.searchKey || ''}
                    bg={colorTheme('#FFFFFF', 'rgba(37, 32, 59, 0.5)')}
                    color="tertiary.300"
                    letterSpacing="0.02em"
                    fontFamily="Montserrat"
                />
            </InputGroup>

            <Filter
                h="100%"
                ml="17px !important"
                alignItems="center"
                filterTitle={t('Mining Filter')}
                filterElement={[
                    <LiveEndBlock key={1} />,
                    <StakeBlock key={2} />,
                    <SortByBlock key={3} />,
                    <Flex ml="auto !important" key={4}>
                        <NetworkSelect />
                    </Flex>,
                ]}
            ></Filter>

            {isPC && (
                <Stack direction={{ base: 'column-reverse', xl: 'row' }} flexGrow={{ base: 0, xl: 1 }} justifyContent="space-between">
                    {account && <StakeBlock />}
                    <LiveEndBlock />
                    <SortByBlock />
                </Stack>
            )}
        </HStack>
    ) : (
        <HStack w="100%" alignItems="start" py="24px" justifyContent={{ base: 'space-between' }} spacing={{ base: '10px', xxl: '20px' }}>
            <InputGroup w={{ base: '280px', sm: '310px' }} h="41px" mt="-4px">
                <Input
                    variant="filled"
                    placeholder={t('Search Tokens or Pools')}
                    size="lg"
                    className={i_text_copy_bold}
                    fontSize="12px"
                    onChange={(e: any) => onSearchKeyChange(e)}
                    value={farm.farmControl.searchKey || ''}
                />
                <InputRightElement h="100%" mt="4px">
                    <SearchIcon boxSize={5} />
                </InputRightElement>
            </InputGroup>
            {!isPC && (
                <Filter
                    h="100%"
                    ml="auto !important"
                    alignItems="center"
                    filterTitle={t('Mining Filter')}
                    filterElement={[<LiveEndBlock key={1} />, <StakeBlock key={2} />, <SortByBlock key={3} />]}
                ></Filter>
            )}
            {isPC && (
                <Stack
                    direction={{ base: 'column-reverse', xl: 'row' }}
                    flexGrow={{ base: 0, xl: 1 }}
                    justifyContent="space-between"
                    spacing={{ base: '8px', sm: '20px', xxl: '8px' }}
                >
                    {account && <StakeBlock />}
                    <LiveEndBlock />
                    <SortByBlock />
                </Stack>
            )}
        </HStack>
    );
};

export default Controls;
