import React from 'react';
import { BoxProps, HStack, Tag, Text, useColorMode, Stack } from '@chakra-ui/react';
import useIsMobile from '../../../hooks/useIsMobile';
import { i_text_copy, i_text_copy_bold } from '../../../style';
import { TokenSymbol } from '../../../types/mod';
import { getColorThemeSelector } from '../../../utils/funcs';
import { Heading } from '../../../iZUMi-UI-toolkit/src/components/Typography/Typography';
import Info from '../../../iZUMi-UI-toolkit/src/components/Info/Info';
import { useTranslation } from 'react-i18next';

export type FeeRateProps = {
    tokenA: TokenSymbol;
    tokenB: TokenSymbol;
    feeTier: any;
    initialToggle?: boolean;
    size?: string;
    showText?: boolean;
} & BoxProps;

export const FeeRate: React.FC<FeeRateProps> = (props) => {
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const { tokenA, tokenB, feeTier, initialToggle, ...rest } = props;
    const isMobile = useIsMobile();
    const { t } = useTranslation();
    const themeColor = {
        base: {
            bg: colorTheme('primary.50', '#3D4375'),
            color: colorTheme('primary.500', 'primary.300'),
        },
        purple: {
            bg: colorTheme('primary.50', '#2E3162'),
            color: colorTheme('primary.500', 'primary.200'),
        },
    };
    return isMobile ? (
        <Stack {...rest}>
            <HStack>
                <Heading color={colorTheme('#1C1D22', '#F9F9FB')} level="5">
                    {!initialToggle ? tokenA + '/' + tokenB : tokenB + '/' + tokenA}
                </Heading>
                <Tag
                    m="0"
                    bg={colorTheme('primary.50', '#3D4375')}
                    color={colorTheme('primary.500', 'primary.300')}
                    className={i_text_copy}
                >
                    <Text className={i_text_copy_bold} fontSize="14px">
                        {' '}
                        {(feeTier / 10000).toFixed(2)}%{' '}
                    </Text>
                </Tag>
            </HStack>
        </Stack>
    ) : (
        <Info
            w="180px"
            {...rest}
            direction={'row'}
            label={
                <Text className={i_text_copy_bold} fontSize="14px !important">
                    {!initialToggle ? tokenA + '/' + tokenB : tokenB + '/' + tokenA}
                </Text>
            }
            value={
                <Tag m="0" bg={themeColor['base'].bg} color={themeColor['base'].color} className={i_text_copy}>
                    <Text className={i_text_copy_bold} fontSize="14px !important">
                        {(feeTier / 10000).toFixed(2)}%{' '}
                    </Text>
                </Tag>
            }
        />
    );
};
