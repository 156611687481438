import { ChevronDownIcon } from '@chakra-ui/icons';
import {
    Button,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Image,
    Text,
    useColorMode,
    HStack,
    BoxProps,
    Box,
    MenuButtonProps,
    IconProps,
    useOutsideClick,
} from '@chakra-ui/react';
import ArrowIcon from '../../../iZUMi-UI-toolkit/src/components/Icons/ArrowIcon/ArrowIcon';
import React, { useMemo, useState } from 'react';
import chains from '../../../config/chains';
import { switchNetwork } from '../../../utils/metamaskWallet';
import { i_text_copy_bold, i_text_copy, i_text_d } from '../../../style';
import { getCurrentConnector, useWeb3WithDefault } from '../../../hooks/useWeb3WithDefault';
import { useRematchDispatch } from '../../../hooks/useRematchDispatch';
import { RootDispatch } from '../../../state/store';
import { getColorThemeSelector } from '../../../utils/funcs';
import { useLocation } from 'react-router-dom';
import placeholder from '../../../assets/placeholder.png';
import { ConnectorNames } from '../../../utils/connectors';
import useAuth from '../../../hooks/useAuth';
import { useToast } from '../../../providers/ToastProvider';
import { useWeb3React } from '@web3-react/core';
import CloseButton from '../../CloseButton/CloseButton';
import { ChainId } from '../../../types/mod';

type Props = {
    isHome?: boolean;
    showArrow?: boolean;
    buttonStyle?: MenuButtonProps;
    rightIconStyle?: IconProps;
} & BoxProps;

const NetworkSelect: React.FC<Props> = ({ isHome, showArrow = true, buttonStyle, rightIconStyle, ...rest }) => {
    const { chainId, account, chainIcon, chainName } = useWeb3WithDefault();
    const { library } = useWeb3React();
    const { dispatch } = useRematchDispatch((dispatch: RootDispatch) => ({
        dispatch,
    }));
    const { login } = useAuth();
    const { show } = useToast();
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const bg1 = colorTheme('#EBEAEC', 'tertiary.700');
    const bg2 = colorTheme('#F5F5F5', 'tertiary.800');
    const [isOpen, setIsOpen] = useState(false);

    const location = useLocation();

    const chainsFilter = useMemo(() => {
        if (location.pathname.startsWith('/farm/uni')) {
            return chains.farmUni;
        }
        if (location.pathname.startsWith('/farm/iZi')) {
            return chains.farmiZi;
        }
        if (location.pathname.startsWith('/veiZi')) {
            return chains.veiZi;
        }
        if (location.pathname.startsWith('/trade')) {
            return chains.trade;
        }
        if (location.pathname.startsWith('/iPoints')) {
            return chains.iPoints;
        }
        return chains.all;
    }, [location]);
    const menuFocusRef = React.useRef<any | null>(null);

    useOutsideClick({
        ref: menuFocusRef,
        handler: () => {
            isOpen && setIsOpen(false);
        },
    });
    return (
        <Box ref={menuFocusRef}>
            <Menu isOpen={isOpen}>
                {isHome ? (
                    <MenuButton
                        h="36px"
                        as={Button}
                        size="sm"
                        bg="none"
                        borderRadius="12px"
                        _hover={{ background: 'unset' }}
                        _active={{ background: 'unset' }}
                        onClick={() => {
                            setIsOpen(!isOpen);
                        }}

                        // leftIcon={
                        //     <Image src={process.env.PUBLIC_URL + chainIcon} boxSize="20px" fallbackSrc={placeholder} borderRadius="24px" />
                        // }
                    >
                        <HStack>
                            <Image boxSize="20px" src="/assets/wallet/linea.png" mr="1px" fallbackSrc={placeholder} />

                            <Text fontSize="14px !important" textAlign="left" className={i_text_copy} fontWeight="500">
                                {chainName}
                            </Text>
                            {/* {showArrow && <ArrowIcon dir="right" color="#4E529A" />} */}
                        </HStack>
                    </MenuButton>
                ) : (
                    <MenuButton
                        as={Button}
                        rightIcon={<ChevronDownIcon boxSize="24px" m="9px" background={bg1} borderRadius="5px" {...rightIconStyle} />}
                        leftIcon={
                            <Image
                                // src={process.env.PUBLIC_URL + chainIcon}
                                src="/assets/wallet/zksync.png"
                                boxSize="24px"
                                ml="17px"
                                mr="7px"
                                fallbackSrc={placeholder}
                                borderRadius="4px"
                            />
                        }
                        style={{
                            height: '40px',
                            //width: '178px',
                            padding: '0',
                            background: { bg2 } as unknown as string,
                            borderRadius: '6px',
                        }}
                        {...buttonStyle}
                    >
                        <Text ml="-10px" className={i_text_copy_bold}>
                            {chainName}
                        </Text>
                    </MenuButton>
                )}
                <MenuList w="290px" bg="#000" p="20px 20px 30px" position="absolute" right="-144px" borderWidth="0px">
                    <HStack w="100%" justifyContent="space-between" pb="15px">
                        <Text className={i_text_d} fontWeight="600" color="#01fba1">
                            Select a Chain
                        </Text>
                        <CloseButton
                            w="13px"
                            h="13px"
                            p="0px"
                            onClose={() => {
                                setIsOpen(false);
                            }}
                        ></CloseButton>
                    </HStack>

                    {chainsFilter.map((chain) => (
                        <MenuItem
                            as={Button}
                            px="0px"
                            variant="light"
                            text={chain.name}
                            size="md"
                            key={chain.id}
                            // isActive={chain.id === chainId}
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-start',
                                borderRadius: '4px',
                                overflow: 'hidden',
                                fontSize: '12px',
                                // fontFamily: 'Montserrat-SemiBold',
                            }}
                            onClick={() => {
                                if (account) {
                                    // switchNetwork(chain.id)
                                    switchNetwork(chain.id, library).then((res) => {
                                        const currentConnector = getCurrentConnector();
                                        if (currentConnector == ConnectorNames.Injected) {
                                            login(ConnectorNames.Injected);
                                        }
                                        if (res) {
                                            show('Error : ', res.toString());
                                        }
                                    });
                                } else {
                                    dispatch.account.setOfflineChainId(chain.id);
                                }
                            }}
                            leftIcon={
                                chain.id === ChainId.LineaTest ? (
                                    <Image
                                        src="/assets/wallet/linea.png"
                                        boxSize="20px"
                                        mr="10px"
                                        fallbackSrc={placeholder}
                                        borderRadius="4px"
                                    />
                                ) : (
                                    <Image
                                        src={process.env.PUBLIC_URL + chain.icon}
                                        boxSize="20px"
                                        mr="10px"
                                        fallbackSrc={placeholder}
                                        borderRadius="4px"
                                    />
                                )
                            }
                        >
                            {chain.id === ChainId.LineaTest ? (
                                <HStack w="100%" justifyContent="space-between">
                                    <Text className={i_text_copy} fontSize="14px !important" fontWeight="500">
                                        {chain.name}
                                    </Text>

                                    <Image boxSize="18px" src="/assets/wallet/check.png" ml="auto"></Image>
                                </HStack>
                            ) : (
                                chain.name
                            )}
                        </MenuItem>
                    ))}
                </MenuList>
            </Menu>
        </Box>
    );
};

export default NetworkSelect;
