import { Flex, useDisclosure, Box, Image, Text, BoxProps, useBreakpointValue, useColorMode, HStack, Stack } from '@chakra-ui/react';
import React, { useState } from 'react';
import useInterval from 'ahooks/lib/useInterval';

import { useHistory } from 'react-router-dom';
import Identicon from '../../../components/Identicon';
import WalletModal from '../../../components/Modal/WalletModal/WalletModal';
import { useRematchDispatch } from '../../../hooks/useRematchDispatch';
import { useWeb3WithDefault } from '../../../hooks/useWeb3WithDefault';
import { useConnectModal } from '../../../providers/ConnectProvider';
import { RootDispatch, RootState } from '../../../state/store';
import truncateWalletAddress from '../../../utils/truncateWalletAddress';
import { getColorThemeSelector } from '../../../utils/funcs';
import './Header.css';
import { useSelector } from 'react-redux';
import { links } from '../../../config/links';
import { ChainId, TokenSymbol } from '../../../types/mod';
import { formatNumber } from '../../../utils/tokenMath';
import LanguageSelect from '../../../components/Sidebar/LanguageSelect/LanguageSelect';
import NetworkSelect from '../../../components/Select/NetworkSelect/NetworkSelect';
import { DropDownMenu } from '../../../components/DropDownMenu/DropDownMenu';
import { useHover } from '../../../hooks/useHover';
import { useIsFixedHeader } from '../../../hooks/useLayoutType';
import BuyiZiButton from '../../../components/BuyiZiButton/BuyiZiButton';
import { i_text_copy } from '../../../style';
import ColorModeSelect from '../../../components/ColorModeSelect/ColorModeSelect';
import { getSafeTokenPrice } from '../../../state/models/token/funcs';
import ConnectButton from '../../../components/ConnectButton/ConnectButton';

const Header: React.FC<BoxProps> = (props) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { onOpenModal: onConnect } = useConnectModal();
    const { chainId, chainName, web3, account } = useWeb3WithDefault();
    const history = useHistory();
    const isPC = useBreakpointValue({ base: false, xl: true });
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const [showHeader, setShowHeader] = useState(true);
    const { token } = useSelector((state: RootState) => state);
    const { account: accountModel } = useSelector((state: RootState) => state);

    const isFixedHeader = useIsFixedHeader();

    const disConnect = () => {
        onOpen();
    };

    const onCopyAddress = () => {
        if (account) {
            navigator.clipboard.writeText(account);
        }
    };

    const { dispatch } = useRematchDispatch((dispatch: RootDispatch) => ({ dispatch }));
    const { pageLayout } = useSelector((state: RootState) => state);
    const [headerRef, isHovering] = useHover<any>();
    // const isXl =

    useInterval(() => {
        if (account && web3 && chainId) {
            dispatch.account.fetchEthBalanceAndUSDIfMissing({
                account,
                web3,
                chainId,
            });
        }
    }, 5000);

    const toolBlock = (
        <HStack spacing="7px" mt="0px !important">
            <Image
                boxSize="24px"
                src={colorTheme('/assets/header/twitter.png', '/assets/header/darkTwitter.png')}
                fallbackSrc={colorTheme('/assets/header/twitter.png', '/assets/header/darkTwitter.png')}
                cursor="pointer"
                onClick={() => {
                    window.open(links.twitter);
                }}
            ></Image>
            {/* <ColorModeSelect showNav={true} flex="unset" ml="15px !important" /> */}
        </HStack>
    );
    const accountBlock = (
        <Flex alignItems="center" ml="10px !important" mt={{ base: '0px !important' }}>
            <Flex px="10px" py="2px" alignItems="center" pr="0px">
                <Flex alignItems="center" flexShrink={0} flexGrow={0} h={{ base: '54px', xl: '54px' }} {...props} zIndex="3">
                    <Flex m="0 auto" justifyContent="flex-end" align="center" flexDirection="row">
                        <HStack borderRadius="8px" border={colorTheme('1px solid #1F1F1F', '1px solid #00FFA1')} spacing="4px" mr="20px">
                            <NetworkSelect isHome={true} showArrow={false} />
                            {/* <LanguageSelect flex="unset" /> */}
                        </HStack>

                        {account ? (
                            <>
                                <Box
                                    h="32px"
                                    bg={colorTheme('#1F1F1F', '#00FFA1')}
                                    mr={{ base: '0px', xxl: '40px' }}
                                    boxSize="border-box"
                                    borderRadius="6px"
                                    cursor="pointer"
                                    onClick={disConnect}
                                >
                                    <Flex height="32px" padding="3px 6px" alignItems="center">
                                        {/* <Box boxSize="22px">
                                            {' '}
                                            <Identicon size={22} />
                                        </Box> */}
                                        <Flex alignItems="center" ml="12px">
                                            <Text
                                                // width="80px"
                                                lineHeight="12px"
                                                fontSize="17px !important"
                                                className={i_text_copy}
                                                color={colorTheme('#00FFA1', '#000000')}
                                            >
                                                {truncateWalletAddress(account || '')}
                                            </Text>
                                            <Text
                                                lineHeight="12px"
                                                fontSize="17px !important"
                                                className={i_text_copy}
                                                mx="5px"
                                                color={colorTheme('#00FFA1', '#000000')}
                                            >
                                                {'|'}
                                            </Text>
                                            <Text
                                                lineHeight="12px"
                                                fontSize="17px !important"
                                                className={i_text_copy}
                                                color={colorTheme('#00FFA1', '#000000')}
                                            >
                                                {formatNumber(accountModel.ethBalance ?? 0, 2, 2)}
                                            </Text>
                                            <Image
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    colorTheme('/assets/wallet/eth.png', '/assets/wallet/darkEth.png')
                                                }
                                                fallbackSrc={
                                                    process.env.PUBLIC_URL +
                                                    colorTheme('/assets/wallet/eth.png', '/assets/wallet/darkEth.png')
                                                }
                                                width="14px"
                                                height="20px"
                                                ml="5px"
                                                mr="8px"
                                            />
                                            {/* <Image boxSize="10px" src="/assets/header/arrowDown.svg" ml="11px"></Image> */}
                                        </Flex>
                                    </Flex>
                                </Box>
                            </>
                        ) : (
                            isPC && <ConnectButton mr="10px" Identicon={Identicon} onClick={onConnect} />
                        )}
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    );
    return (
        <>
            <Flex
                // className={pageLayout.inAnimation ? 'showHeader' : ''}
                alignItems="center"
                flexShrink={0}
                flexGrow={0}
                h={{ base: '62px', xl: '62px' }}
                bg={colorTheme('#ffffff', '#000000')}
                boxShadow={colorTheme('0px 0px 23px rgba(0,255,161,0.1)', '0px 0px 23px rgba(0,255,161,0.3)')}
                bgSize="cover"
                position={'sticky'}
                top="0px"
                transitionProperty="all"
                transitionDuration="0.3s"
                transitionTimingFunction="ease"
                zIndex="1"
                {...props}
            >
                {showHeader && (
                    <Flex
                        w="100%"
                        h="100%"
                        m="0 auto"
                        alignItems={{ base: 'center', xl: 'center' }}
                        // flexDirection={{ base: 'column', xl: 'row' }}
                    >
                        <HStack w={{ base: 'unset', xl: 'unset' }} h="100%" px="40px" alignItems="center">
                            <HStack w={{ base: 'unset', sm: '150px' }} justifyContent="center">
                                <Image
                                    src={colorTheme(
                                        process.env.PUBLIC_URL + '/assets/home/iziLogo/logo.svg',
                                        '/assets/home/iziLogo/darkLogo.svg'
                                    )}
                                    width="104px"
                                    mb="5px"
                                    cursor="pointer"
                                    onClick={() => {
                                        window.open(links.surfi, '_self');
                                    }}
                                />
                            </HStack>
                            <DropDownMenu
                                buttonProps={{
                                    fontFamily: 'Montserrat-Medium',
                                    fontSize: '13px',
                                    px: '0px',
                                    // _hover: { color: colorTheme('#4F20B3', '#7F4AFE'), bg: 'none !important' },
                                    // _active: { color: colorTheme('#4F20B3', '#7F4AFE'), bg: 'none !important' },
                                    ml: '0px !important',
                                }}
                                menuListProps={{
                                    fontFamily: 'Montserrat-Medium',
                                    bg: colorTheme('#FFFFFF', '#292343'),
                                    color: colorTheme('#A7A1AC !important', '#655986 !important'),
                                    _hover: { color: colorTheme('#4F20B3 !important', '#7F4AFE !important') },
                                }}
                                h="100%"
                                alignItems={{ base: 'start', xl: 'center' }}
                                pl="50px"
                            ></DropDownMenu>
                        </HStack>

                        <Stack
                            direction={{ base: 'row', xl: 'row' }}
                            flex={{ base: '1', xl: '1' }}
                            justifyContent="end"
                            alignItems={{ base: 'center', xl: 'center' }}
                            mr="20px"
                        >
                            {toolBlock}
                            {account ? (
                                accountBlock
                            ) : (
                                <Flex ml="20px !important" alignItems="center" pr="0px">
                                    <HStack
                                        borderRadius="8px"
                                        border={colorTheme('1px solid #1F1F1F', '1px solid #00FFA1')}
                                        spacing="4px"
                                        mr="20px"
                                    >
                                        <NetworkSelect isHome={true} showArrow={false} />
                                        {/* <LanguageSelect flex="unset" /> */}
                                    </HStack>
                                    <ConnectButton variant="purple" mr="10px" Identicon={Identicon} onClick={onConnect} />
                                </Flex>
                            )}
                        </Stack>
                    </Flex>
                )}
            </Flex>
            <WalletModal
                account={account as unknown as string}
                chainId={chainId!}
                isOpen={isOpen}
                onClose={onClose}
                onCopyAddress={onCopyAddress}
                scanName={chainName}
            />
        </>
    );
};

export default Header;
