import { TokenInfoFormatted } from '../../../../hooks/useTokenListFormatted';
import { ChainId, TokenSymbol, FarmDynamicRangeiZiContractVersion } from '../../../../types/mod';
import { sortedToken } from '../../../../utils/tokenMath';

export interface MiningPoolMetaConfig {
    tokenA: TokenInfoFormatted;
    tokenB: TokenInfoFormatted;
    feeTier: FeeTier;
    miningContract?: any;
    iZiSwapAddress: string;
    initialToggle?: boolean;

    iZiBoost?: boolean;
    veiZiBoost?: boolean;
    contractVersion?: FarmDynamicRangeiZiContractVersion;
    twoRewards?: boolean;
    noFee?: boolean;
    feeCharged?: number;

    priceRangeRatio?: number;

    // if priceRangeRatio is undefined, means
    // leftRangeRatio != rightRangeRatio
    // leftRangeRatio >= 1
    leftRangeRatio?: number;
    // rightRangeRatio >= 1
    rightRangeRatio?: number;
    isEnded?: boolean;

    useTimestamp?: boolean;
    useOriginLiquidity?: true,

    additionalKey?: string;
}

export const miningPoolConfigList: Partial<Record<ChainId, MiningPoolMetaConfig[]>> =
    process.env.REACT_APP_ENV === 'production'
        ? {
            [ChainId.EthereumMainnet]: [],
            [ChainId.Matic]: [],
            [ChainId.BSC]: [],
            [ChainId.Arbitrum]: [],
            [ChainId.ZkSyncEra]: [
                {
                    ...sortedToken(TokenSymbol.PIKO, TokenSymbol.ETH, ChainId.ZkSyncEra),
                    miningContract: '0x581780EB38e1B5115B310B538fa34C361d826d18',
                    iZiSwapAddress: '0x2f801cc2b7213be05fd73febb80b627cfd625c9f',
                    feeTier: 1,
                    twoRewards: true,
                    iZiBoost: true,
                    contractVersion: FarmDynamicRangeiZiContractVersion.V1,
                    noFee: false,
                    feeCharged: 1,
                    leftRangeRatio: 4,
                    rightRangeRatio: 4,
                    isEnded: false,
                    useTimestamp: true,
                },
                {
                    ...sortedToken(TokenSymbol.USDC, TokenSymbol.zkUSD, ChainId.ZkSyncEra),
                    miningContract: '0xFcc21e03b25BC8cA918D497fb014fa3491503c0c',
                    iZiSwapAddress: '0x0460c2236809140dac7cb22d3e3a7eb9fd4aefbd',
                    feeTier: 0.2,
                    twoRewards: true,
                    iZiBoost: true,
                    contractVersion: FarmDynamicRangeiZiContractVersion.V1,
                    noFee: false,
                    feeCharged: 1,
                    leftRangeRatio: 1.010101010101,
                    rightRangeRatio: 1.01,
                    isEnded: false,
                    useTimestamp: true,
                    useOriginLiquidity: true,
                },
                {
                    ...sortedToken(TokenSymbol.ETH, TokenSymbol.zkUSD, ChainId.ZkSyncEra),
                    miningContract: '0x2c38130dfF9097F9486Ee0A53f5261e9c6acd6ad',
                    iZiSwapAddress: '0x23de0ad8b08195b3bcefd58060837b4f87fc190e',
                    feeTier: 1,
                    twoRewards: true,
                    iZiBoost: true,
                    contractVersion: FarmDynamicRangeiZiContractVersion.V1,
                    noFee: false,
                    feeCharged: 1,
                    leftRangeRatio: 1.333333333333,
                    rightRangeRatio: 1.3,
                    isEnded: false,
                    useTimestamp: true,
                },
                {
                    ...sortedToken(TokenSymbol.KANA, TokenSymbol.USDC, ChainId.ZkSyncEra),
                    miningContract: '0xA7Fb8cd35409062a7D811535B7b0c2274335D5bD',
                    iZiSwapAddress: '0xa65b7ca8f35f1fd7e778fd435e276f6e0205e143',
                    feeTier: 0.2,
                    twoRewards: true,
                    iZiBoost: true,
                    contractVersion: FarmDynamicRangeiZiContractVersion.V1,
                    noFee: false,
                    feeCharged: 1,
                    priceRangeRatio: 4,
                    isEnded: false,
                    useTimestamp: true,
                },
                {
                    ...sortedToken(TokenSymbol.GGG, TokenSymbol.ETH, ChainId.ZkSyncEra),
                    miningContract: '0xA7f0A6162567E2E9d77f81C8bc7a2E18F19f5d28',
                    iZiSwapAddress: '0xb6bfdfa8f2f2a4bef0ed03f39f77c48b0a2fb743',
                    feeTier: 0.2,
                    twoRewards: true,
                    iZiBoost: true,
                    contractVersion: FarmDynamicRangeiZiContractVersion.V1,
                    noFee: false,
                    feeCharged: 1,
                    priceRangeRatio: 4,
                    isEnded: false,
                    useTimestamp: true,
                },
                {
                    ...sortedToken(TokenSymbol.LSD, TokenSymbol.ETH, ChainId.ZkSyncEra),
                    miningContract: '0x893feD28e2d1599a513498d6CF6D0Fb5dA5fbbd4',
                    iZiSwapAddress: '0xef0783943a07e3822f5094497767ff8888144b5d',
                    feeTier: 1,
                    twoRewards: true,
                    iZiBoost: true,
                    contractVersion: FarmDynamicRangeiZiContractVersion.V1,
                    noFee: false,
                    feeCharged: 1,
                    priceRangeRatio: 4,
                    isEnded: false,
                    useTimestamp: true,
                },
                {
                    ...sortedToken(TokenSymbol.BLADE, TokenSymbol.USDC, ChainId.ZkSyncEra),
                    miningContract: '0x8baCe5229771d2909924B055aCd2f109EB4cf8a8',
                    iZiSwapAddress: '0x6fdc8ba4428db4c72b17e6c59ba1ec6de5baeb16',
                    feeTier: 0.2,
                    twoRewards: true,
                    iZiBoost: true,
                    contractVersion: FarmDynamicRangeiZiContractVersion.V1,
                    noFee: false,
                    feeCharged: 1,
                    priceRangeRatio: 2,
                    isEnded: false,
                    useTimestamp: true,
                },
                {
                    ...sortedToken(TokenSymbol.DEXTF, TokenSymbol.USDC, ChainId.ZkSyncEra),
                    miningContract: '0x353a12B0D46618c513bF5313AB7DfFB01227C234',
                    iZiSwapAddress: '0xa9addc3bb68dd601cc9d9ba6b0e5e4651a7c476f',
                    feeTier: 0.2,
                    twoRewards: true,
                    iZiBoost: true,
                    contractVersion: FarmDynamicRangeiZiContractVersion.V1,
                    noFee: false,
                    feeCharged: 1,
                    priceRangeRatio: 4,
                    isEnded: false,
                    useTimestamp: true,
                },
                {
                    ...sortedToken(TokenSymbol.ZKSP, TokenSymbol.ETH, ChainId.ZkSyncEra),
                    miningContract: '0x28702B2b58cCb5927b53aF91E5dC63270273d4C3',
                    iZiSwapAddress: '0x3a83b68de90a660e1780df9c84d57de3fa832e66',
                    feeTier: 1,
                    twoRewards: true,
                    iZiBoost: true,
                    contractVersion: FarmDynamicRangeiZiContractVersion.V1,
                    noFee: false,
                    feeCharged: 1,
                    priceRangeRatio: 4,
                    isEnded: false,
                    useTimestamp: true,
                },
                {
                    ...sortedToken(TokenSymbol.RF, TokenSymbol.ETH, ChainId.ZkSyncEra),
                    miningContract: '0x0C4E0F1Ff3B200db601b5A27adBaD288e804A35B',
                    iZiSwapAddress: '0x0f149b3f475d0acbc2ab849b50a0e14593450997',
                    feeTier: 0.2,
                    twoRewards: true,
                    iZiBoost: true,
                    contractVersion: FarmDynamicRangeiZiContractVersion.V1,
                    noFee: false,
                    feeCharged: 1,
                    priceRangeRatio: 4,
                    isEnded: false,
                    useTimestamp: true,
                },
                {
                    ...sortedToken(TokenSymbol.OT, TokenSymbol.USDC, ChainId.ZkSyncEra),
                    miningContract: '0xcB27b19c27e316f2deE2f07123716F36368C9e97',
                    iZiSwapAddress: '0x3fc25ee9c05eeddecd04f347b8ed702500993ec3',
                    feeTier: 0.2,
                    twoRewards: true,
                    iZiBoost: true,
                    contractVersion: FarmDynamicRangeiZiContractVersion.V1,
                    noFee: false,
                    feeCharged: 1,
                    priceRangeRatio: 4,
                    isEnded: false,
                    useTimestamp: true,
                },
                {
                    ...sortedToken(TokenSymbol.SPACE, TokenSymbol.ETH, ChainId.ZkSyncEra),
                    miningContract: '0x78e1F126a1BAE67bBC05A92CF2Bc171C69ee250a',
                    iZiSwapAddress: '0x682a471baa1e77d447b4a670925eb7c9ad752324',
                    feeTier: 0.2,
                    twoRewards: true,
                    iZiBoost: true,
                    contractVersion: FarmDynamicRangeiZiContractVersion.V1,
                    noFee: false,
                    feeCharged: 1,
                    priceRangeRatio: 4,
                    isEnded: false,
                    useTimestamp: true,
                },

            ]
        }
        : {
            [ChainId.Rinkeby]: [],
            [ChainId.BSCTestnet]: [],
            [ChainId.AuroraTestnet]: [],
            [ChainId.ZkSyncAlphaTest]: [
                {
                    ...sortedToken(TokenSymbol.MIT, TokenSymbol.USDT, ChainId.ZkSyncAlphaTest),
                    miningContract: '0x254C23Db2E3D309c6025Ff54eb5f6aD6a25fFc43',
                    iZiSwapAddress: '0x6D7607fe4457A82BC7bAf1F22B038accC7044068',
                    useTimestamp: true,
                    feeTier: 0.2,
                    twoRewards: true,
                    iZiBoost: true,
                    contractVersion: FarmDynamicRangeiZiContractVersion.V1,
                    noFee: false,
                    feeCharged: 1,
                    leftRangeRatio: 2,
                    rightRangeRatio: 2,
                },
            ]
        };
