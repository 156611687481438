import React, { useEffect } from 'react';
import {
    Text,
    Flex,
    Icon,
    IconButton,
    Button,
    Box,
    Image,
    useColorMode,
    Divider,
    VStack,
    HStack,
    useInterval,
    Stack,
} from '@chakra-ui/react';
import CustomButton from '../../../iZUMi-UI-toolkit/src/components/Buttons/CustomButton/CustomButton';

import useAuth from '../../../hooks/useAuth';
import { BiLinkExternal } from 'react-icons/bi';
import { getScanUrlPrefix } from '../../../config/chains';
import Identicon from '../../Identicon';
import { getColorThemeSelector } from '../../../utils/funcs';
import { formatNumber } from '../../../utils/tokenMath';
import { useSelector } from 'react-redux';
import { RootDispatch, RootState } from '../../../state/store';
import truncateWalletAddress from '../../../utils/truncateWalletAddress';
import { i_text_copy_bold, i_text_d, i_h4, i_h2, i_text_copy } from '../../../style';
import { setCurrentConnector, useWeb3WithDefault } from '../../../hooks/useWeb3WithDefault';
import { useRematchDispatch } from '../../../hooks/useRematchDispatch';
import { AccountTokenParams } from '../../../state/models/account/account';
import { ACCOUNT_CONFIG } from '../../../config/bizConfig';
import useWindowDimensions from '../../../hooks/useWindowDimension';
import { useHistory } from 'react-router-dom';
import useIsMobile from '../../../hooks/useIsMobile';
import { useTranslation } from 'react-i18next';
import { logoutWithParticle, particle } from '../../../utils/particle';
import { ConnectorNames } from '../../../utils/connectors';
import { Modal } from '../Modal';

interface Props {
    isOpen: boolean;
    onClose: () => void;
    onCopyAddress: () => void;
    account: string;
    scanName: string;
    chainId: number;
}

const WalletModal: React.FC<Props> = ({ isOpen, onClose, account, scanName, chainId, onCopyAddress }) => {
    const { logout } = useAuth();
    const isMobile = useIsMobile();

    const viewAccountSelf = () => window.open(getScanUrlPrefix(chainId) + account, '_blank');

    const { t } = useTranslation();
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const { account: accountModel } = useSelector((state: RootState) => state);
    const addressBgColor = colorTheme('#F5F5F5', '#18102D9E');
    const { web3 } = useWeb3WithDefault();
    const { dispatch } = useRematchDispatch((dispatch: RootDispatch) => ({
        dispatch,
    }));
    useEffect(() => {
        if (account && web3 && chainId) {
            dispatch.account.changeAccount({ address: account, chainId });
            dispatch.account.fetchEthBalanceAndUSDIfMissing({
                account,
                web3,
                chainId,
            });
        }
    }, [account, chainId, web3, dispatch.account]);
    useInterval(() => {
        if (account && web3 && chainId) {
            dispatch.account.refreshTokenBalance({
                account,
                web3,
                chainId,
            } as AccountTokenParams);
        }
    }, ACCOUNT_CONFIG.AUTO_REFRESH_TOKEN_BALANCE_INTERVAL);

    const { width } = useWindowDimensions();
    const changeLineWidth = 480;
    const isChangeLine = changeLineWidth > width ? true : false;
    const history = useHistory();

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            w={{ base: '80%', lg: '526px' }}
            h="unset"
            title={t('Your Wallet')}
            top="52%"
            modalBg={colorTheme('#ffffff', '#000')}
            bg={colorTheme('#ffffff', '#000')}
            border="1px solid #00FFA1"
            borderRadius="10px"
        >
            <Stack
                direction={isChangeLine ? 'column' : 'row'}
                w="100%"
                h={isChangeLine ? 'unset' : 'unset'}
                alignItems={isChangeLine ? 'left' : 'unset'}
            >
                <Flex w={isChangeLine ? 'unset' : '50%'} h="100%" justifyContent={isChangeLine ? 'center' : 'unset'}>
                    <Identicon />
                    <VStack ml={isChangeLine ? '26px' : '20px'} spacing="6px" alignItems="left">
                        <Text className={i_text_d} color={colorTheme('tertiary.800', 'tertiary.100')}>
                            {t('Account')}
                        </Text>
                        <HStack>
                            <Text h="15px" color={colorTheme('tertiary.300', '#C1C1C1')} className={i_text_copy}>
                                {truncateWalletAddress(account || '', 8)}
                            </Text>

                            <Image w="12px" h="12px" src="/assets/wallet/darkCopy.png" cursor="pointer" onClick={onCopyAddress}></Image>
                        </HStack>
                    </VStack>
                </Flex>
                <Box w={isChangeLine ? 'unset' : '50%'} h="100%">
                    <Flex
                        h="100%"
                        float={isChangeLine ? 'unset' : 'right'}
                        alignItems="center"
                        mr={isChangeLine ? 'unset' : '6px'}
                        ml={isChangeLine ? '60px' : 'unset'}
                    >
                        <VStack ml="6px" textAlign="right" alignItems="end" mb="10px">
                            <HStack spacing="10px" mr={isChangeLine ? 'auto' : 'unset'} justifyItems={isChangeLine ? 'unset' : 'center'}>
                                <Text className={i_h2} fontWeight="bold" color={colorTheme('tertiary.800', 'tertiary.100')}>
                                    {formatNumber(accountModel.ethBalance ?? 0, 2, 2)}
                                </Text>
                            </HStack>
                            <Text className={i_text_copy} fontSize="12px" color="#C1C1C1" mt="0px !important">
                                ≈{formatNumber(accountModel.ethBalanceUSD ?? 0, 2, 2)} USD
                            </Text>
                        </VStack>
                    </Flex>
                </Box>
            </Stack>

            <HStack w="100%" spacing="20px" position="relative" pt="30px">
                <CustomButton
                    w={'unset'}
                    h="30px"
                    variant="orange"
                    text={'Log out '}
                    fontClass={i_text_copy}
                    fontSize="14px"
                    color={colorTheme('#000000', '#ffffff')}
                    border={colorTheme('1px solid #66FFC7', '1px solid #00ffa1')}
                    borderRadius="7px"
                    bg="none"
                    px="24px"
                    _hover={{ bg: colorTheme('#66FFC7', '#009961') }}
                    onClick={() => {
                        onClose();
                        if (particle.auth.isLogin()) {
                            logoutWithParticle();
                            setCurrentConnector(ConnectorNames.Injected);
                            dispatch.loginConfig.setLoginConfig(ConnectorNames.Injected);
                        } else {
                            logout();
                            setCurrentConnector(ConnectorNames.Injected);
                            dispatch.loginConfig.setLoginConfig(ConnectorNames.Injected);
                        }
                    }}
                />
                <CustomButton
                    w={'unset'}
                    h="30px"
                    variant="orange"
                    text={'View on ' + scanName}
                    fontSize="14px"
                    fontClass={i_text_copy}
                    color={colorTheme('#000000', '#ffffff')}
                    border={colorTheme('1px solid #66FFC7', '1px solid #00ffa1')}
                    borderRadius="7px"
                    bg="none"
                    pl="20px"
                    pr="10px"
                    _hover={{ bg: colorTheme('#66FFC7', '#009961') }}
                    rightIcon={
                        <IconButton
                            ml="2px"
                            aria-label="explore"
                            variant="ghost"
                            size="sm"
                            icon={<Icon as={BiLinkExternal} boxSize={'18px'} />}
                            color={colorTheme('#66FFC7', '#00F198')}
                        />
                    }
                    onClick={() => {
                        viewAccountSelf();
                    }}
                />
                {/* <CustomButton
                    mx="auto"
                    w={isMobile ? '100px' : '206px'}
                    h="40px"
                    variant="primary2"
                    text={isMobile ? t('History') : t('Order History')}
                    fontClass={i_text_copy_bold}
                    onClick={() => {
                        onClose();
                        history.push('/order-history');
                    }}
                /> */}
            </HStack>
        </Modal>
    );
};

export default WalletModal;
