import { links } from '../links';

export const config = {
    pages: [
        {
            name: 'Trade',
            img: 'Trade.svg',
            address: '/swap',
            marker: 'new',
            inTabBar: true,
            inHeader: true,
            isDisable: false,
            children: [],
        },
        {
            name: 'SBT Points',
            img: 'Analytics.svg',
            address: links.surfiSTBPoints,
            inTabBar: true,
            inHeader: true,
            isDisable: false,
            children: [],
        },
    ],
} as { pages: PageConfig[] };
