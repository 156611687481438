import {
    Button,
    Menu,
    MenuButton,
    MenuList,
    Image,
    Box,
    Flex,
    useDisclosure,
    ChakraProps,
    BoxProps,
    useColorMode,
    Divider,
    VStack,
    Text,
    Stack,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { links } from '../../../config/links';
import { useWeb3WithDefault } from '../../../hooks/useWeb3WithDefault';
import CustomButton from '../../../iZUMi-UI-toolkit/src/components/Buttons/CustomButton/CustomButton';
import { a_display, i_text_copy } from '../../../iZUMi-UI-toolkit/src/style';
import { getColorThemeSelector } from '../../../utils/funcs';
type DropDownButtonProps = {
    page: any;
    variant?: string;
    rightIcon?: any;
    buttonProps?: ChakraProps;
    menuListProps?: ChakraProps;
    index?: number;
    setBtHoverId?: any;
} & BoxProps;
export const DropDownButton: React.FC<DropDownButtonProps> = (props) => {
    const { page, rightIcon, buttonProps, menuListProps, index, setBtHoverId, ...rest } = props;
    const { isOpen, onOpen, onClose } = useDisclosure();
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const { t } = useTranslation();
    const history = useHistory();
    const [hoverId, setHoverId] = useState(-1);
    const location = useLocation();
    const currentPath = location.pathname;
    const selected = page.address === currentPath;
    const { chainId } = useWeb3WithDefault();

    return page.children.length === 0 ? (
        <VStack pr={selected ? '0px' : '75px'} h="100%" justifyContent="center" spacing="0px" pl="10px" position="relative">
            <CustomButton
                h={selected ? { base: '27px', xl: '27px' } : { base: '27px', xl: '27px' }}
                text={t(page.name)}
                bg="inherit"
                className={i_text_copy}
                fontSize="15px !important"
                color={colorTheme('#1F1F1F', '#FFFFFF')}
                // fontWeight="600"
                fontFamily="Montserrat !important"
                px="5px"
                letterSpacing="0em !important"
                justifyContent={{ base: 'left', xl: 'center' }}
                disabled={page.isDisable}
                _disabled={{ color: '#747474', bg: 'none', cursor: 'not-allowed' }}
                onClick={() => {
                    if (page.address && page.address.startsWith('http')) {
                        if (page.address === links.analyticLink) {
                            window.open(page.address + '/Dashboard?chainId=' + chainId);
                        } else {
                            window.open(page.address, '_self');
                        }
                    } else {
                        history.push(page.address);
                    }
                }}
            ></CustomButton>
            {/* {!selected && (
                <Stack bg="#5b5b5b" position="absolute" top="5px" right="0px" px="3px" py="1px" borderRadius="12px">
                    <Text className={i_text_copy} lineHeight="12px" letterSpacing="0em" color="#b8b8b8">
                        Coming Soon
                    </Text>
                </Stack>
            )} */}
            {selected ? <Divider border="3px solid #00FFA1" borderColor={'#00FFA1'} borderRadius="5px"></Divider> : <Box h="3px"></Box>}
        </VStack>
    ) : (
        <Menu isOpen={isOpen} {...rest}>
            <MenuButton
                as={Button}
                rightIcon={rightIcon}
                h="100%"
                bg="inherit"
                className={a_display}
                fontSize="18px !important"
                onMouseEnter={onOpen}
                onMouseLeave={() => {
                    setBtHoverId(-1);
                    onClose();
                }}
                onMouseOver={() => {
                    setBtHoverId(index);
                }}
                _hover={{ background: '#7D68E6' }}
                _active={{ background: '#7D68E6' }}
                pr={{ base: '10px', lg: '20px', xl: '44px' }}
                color={selected ? colorTheme('#4F20B3', '#7F4AFE') : '#9388AD'}
                {...buttonProps}
            >
                {t(page.name)}
            </MenuButton>
            <MenuList
                minW={'0px'}
                bg="rgba(20, 2, 51);"
                onMouseEnter={onOpen}
                onMouseLeave={onClose}
                borderWidth="0px"
                transform="translate(0px, -8px) !important"
                {...menuListProps}
            >
                {page.children.map((child: any, index: number) => {
                    const tempImg = colorTheme('/assets/header/' + child.icon, '/assets/header/dark' + child.icon);
                    const hoverImg = colorTheme('/assets/header/Hover' + child.icon, '/assets/header/Hoverdark' + child.icon);
                    const img = hoverId === index ? hoverImg : tempImg;
                    return child.address?.startsWith('http') ? (
                        <Link key={child.name + child.address} target="_blank" to={{ pathname: child.address }}>
                            <Box
                                display="block"
                                fontFamily="Montserrat-Medium"
                                fontSize="13px"
                                pt="13px"
                                mr="34px"
                                pl="27px"
                                pb="11px"
                                target="_blank"
                                color="tertiary.50"
                                onMouseOver={() => {
                                    setHoverId(index);
                                }}
                                onMouseLeave={() => {
                                    setHoverId(-1);
                                }}
                                {...menuListProps}
                            >
                                <Flex>
                                    <Image boxSize={'20px'} mr="14px" src={img} fallbackSrc={img}></Image>

                                    {child.name}
                                </Flex>
                                {index !== page.children.length - 1 && (
                                    <Image
                                        w="100%"
                                        src={colorTheme('/assets/header/purpleLine.svg', '/assets/header/grayLine.svg')}
                                        pt="13px"
                                    ></Image>
                                )}
                            </Box>
                        </Link>
                    ) : (
                        <Link to={child.address as string} key={child.name + child.address}>
                            <Box
                                maxW="172px"
                                display="block"
                                fontSize="13px"
                                fontFamily="Montserrat-Medium"
                                pt="13px"
                                mr="34px"
                                pl="27px"
                                pb="11px"
                                target="_blank"
                                color="tertiary.50"
                                whiteSpace="nowrap"
                                onMouseOver={() => {
                                    setHoverId(index);
                                }}
                                onMouseLeave={() => {
                                    setHoverId(-1);
                                }}
                                {...menuListProps}
                            >
                                <Flex fontFamily={'Montserrat-Medium'} fontSize="13px" lineHeight="20px">
                                    <Image boxSize={'20px'} mr="14px" src={img} fallbackSrc={img}></Image>
                                    {child.name}
                                </Flex>
                                {index !== page.children.length - 1 && (
                                    <Image
                                        w="100%"
                                        src={colorTheme('/assets/header/purpleLine.svg', '/assets/header/grayLine.svg')}
                                        pt="13px"
                                    ></Image>
                                )}
                            </Box>
                        </Link>
                    );
                })}
            </MenuList>
        </Menu>
    );
};
