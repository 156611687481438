import { ChainId } from '../../types/mod';

export const baseURL = 'https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/bridge/';

export const BRIDGE = {
    [ChainId.ZkSyncEra]: [
        {
            titleImgSrc: baseURL + 'era.svg',
            title: 'zkSync Era Bridge',
            content: "zkSync Era's official bridge that can securely bridge Ethereum's native assets in the safest way possible.",
            link: 'https://bridge.zksync.io/',
        },
        {
            titleImgSrc: baseURL + 'cbridge.png',
            title: 'cBridge',
            content:
                'cBridge is powered by Celer network, which is a decentralized and non-custodial asset bridge that supports 40+ blockchains.',
            link: 'https://cbridge.celer.network/1/324/USDC',
        },
        {
            titleImgSrc: baseURL + 'orbiter.png',
            title: 'Orbiter',
            content:
                'Orbiter Finance is a decentralized cross-rollup bridge for transferring the Ethereum native assets, which is the infrastructure of Layer 2, it offers low cost and almost instant transfers.',
            link: 'https://www.orbiter.finance/?source=Arbitrum&dest=zkSync%20Era',
        },
        {
            titleImgSrc: baseURL + 'multichain.png',
            title: 'Multichain',
            content:
                'Multichain Router allows users to swap between any two chains freely. It reduces fees and makes it easier to move between chains.',
            link: 'https://app.multichain.org/#/router',
        },
    ],
} as any;
