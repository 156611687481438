import { useEffect, useRef } from 'react';
import styled from 'styled-components/macro';
import Jazzicon from '@metamask/jazzicon';
import { useWeb3WithDefault } from '../../hooks/useWeb3WithDefault';

const StyledIdenticonContainer = styled.div`
    border-radius: 1.125rem;
    background-color: ${({ theme }) => theme.bg4};
`;

type Props = {
    size?: number;
};

export default function Identicon(props: Props): JSX.Element {
    const ref = useRef<HTMLDivElement>();
    const { account: accountRaw } = useWeb3WithDefault();
    const size = props.size ?? 50;

    useEffect(() => {
        const account = accountRaw ?? '0x0000000000000000000000000000000000000000';
        if (account && ref.current) {
            ref.current.innerHTML = '';
            ref.current.appendChild(Jazzicon(size, parseInt(account.slice(2, 10), 16)));
        }
    }, [accountRaw]);

    // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/30451
    return <StyledIdenticonContainer ref={ref as any} />;
}
