import { ChainId } from '../../types/mod';

export const baseURL = 'https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/portal/';

export const PORTAL = {
    [ChainId.ZkSyncEra]: [
        {
            titleImgSrc: baseURL + 'izi.svg',
            title: 'Main Site',
            content:
                "iZUMi.finance's main website, where all the supported public chains (mainnet), such as BSC, Arbitrum, Polygon, and Lp farming activities of iZiSwap are gathered.",
            link: 'https://izumi.finance',
        },
        {
            titleImgSrc: baseURL + 'veiZi.svg',
            title: 'veiZi',
            content: 'Lock iZi for veiZi to get voting power and staking rewards on Ethereum. Become an owner of iZUMi',
            link: 'https://izumi.finance/veiZi/locker',
        },
        {
            titleImgSrc: baseURL + 'iZi-scroll.svg',
            title: 'iZiSwap@Scroll',
            content: 'A website specifically designed for iZiSwap on Scroll network. It is currently in the testing phase. ',
            link: 'https://scroll.izumi.finance',
        },
        {
            titleImgSrc: baseURL + 'iZi-mantle.svg',
            title: 'iZiSwap@Mantle',
            content: 'A website specifically designed for iZiSwap on Mantle network. It is currently in the testing phase. ',
            link: 'https://mantle.izumi.finance',
        },
        {
            titleImgSrc: baseURL + 'iZi-linea.svg',
            title: 'iZiSwap@Linea',
            content: 'A website specifically designed for iZiSwap on Linea network. It is currently in the testing phase. ',
            link: 'https://linea.izumi.finance',
        },
        {
            titleImgSrc: baseURL + 'izi.svg',
            title: 'Portfolio',
            content: 'A tool provided by iZUMi for monitoring LP situations on different chains for concentrated AMM protocols.',
            link: 'https://portfolio.izumi.finance',
        },
        {
            titleImgSrc: baseURL + 'izi.svg',
            title: 'Test Env',
            content: "iZUMi's testing environment that allows you to experience it freely without worrying about security risks.",
            link: 'https://alpha.izumi.finance',
        },
        {
            titleImgSrc: baseURL + 'openocean.png',
            title: 'OpenOcean',
            content: 'iZUMi\'s close partner. The leading DEX Aggregator, offering the best swap rate across 27+ chains',
            link: 'https://app.openocean.finance/CLASSIC#/ZKSYNC/ETH/USDC',
        },
    ],
} as any;
