import { BoxProps, Flex, Text, HStack, useColorMode, Box } from '@chakra-ui/react';
import Card from '../../components/Card/Card';
import { i_text_copy_bold } from '../../style';
import { getColorThemeSelector } from '../../utils/funcs';
import CloseButton from '../CloseButton/CloseButton';

type ModalProps = {
    isOpen: boolean | any;
    onClose: any;
    title: string | any;
    children: any;
    modalBg?: any;
    titleProps?: BoxProps;
    contentProps?: BoxProps;
    isShowClose?: boolean;
    isShowHeader?: boolean;
} & BoxProps;

export const Modal: React.FC<ModalProps> = (props) => {
    const { isOpen, onClose, title, children, modalBg, titleProps, contentProps, isShowClose = true, isShowHeader = true, ...rest } = props;
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const bgColor = modalBg ?? colorTheme('#ffffff', '#000');
    return isOpen ? (
        <>
            <Flex
                w="200%"
                h="200%"
                top="-200px"
                left="-200px"
                bg="black"
                opacity={colorTheme(0.15, 0.4)}
                position="fixed"
                zIndex={10}
                onClick={() => {
                    onClose();
                }}
            ></Flex>

            <Card
                variant="deep2"
                borderRadius="2px"
                zIndex={11}
                position="fixed"
                top="50%"
                left="50%"
                maxH="95%"
                overflowY="scroll"
                css={{
                    '&::-webkit-scrollbar': {
                        display: 'none !important',
                    },
                }}
                transform="translateX(-50%) translateY(-50%)"
                pb={{ base: '35px', sm: '36px' }}
                border="1px solid #00FFA1"
                {...rest}
            >
                {isShowHeader && (
                    <HStack
                        justifyContent="space-between"
                        top="0px"
                        pos="sticky"
                        bgColor={bgColor}
                        zIndex="3"
                        pt={{ base: '35px', sm: '30px' }}
                        pb={{ base: '11px', sm: '10px' }}
                        px={{ base: '22px', lg: '30px' }}
                        mb={{ base: '0px', sm: '5px' }}
                        {...titleProps}
                    >
                        {typeof title === 'string' ? (
                            <Text className={i_text_copy_bold} fontSize="18px !important" color={colorTheme('#1F1F1F', '#00FFA1')}>
                                {title}
                            </Text>
                        ) : (
                            title
                        )}
                        {isShowClose && <CloseButton onClose={onClose} />}
                    </HStack>
                )}
                <Box px={{ base: '22px', lg: '30px' }} {...contentProps}>
                    {children}
                </Box>
            </Card>
        </>
    ) : (
        <></>
    );
};
