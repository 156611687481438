import { Box, Stack } from '@chakra-ui/layout';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { MdAddChart } from 'react-icons/md';
import { useLocation } from 'react-router-dom';
import React, { useCallback, useEffect, useState } from 'react';
import { useBreakpointValue } from '@chakra-ui/media-query';
import { Button, useColorMode, Icon, Text, HStack, Center, VStack, Image } from '@chakra-ui/react';
import JumpButton from '../../../iZUMi-UI-toolkit/src/components/Buttons/JumpButton/JumpButton';
import { Heading } from '../../../iZUMi-UI-toolkit/src/components/Typography/Typography';
import SwapForm from './SwapForm';
import { i_h3, i_h4, i_text_d } from '../../../style';
import Tabs from '../components/Tabs';
import AdvanceSwap from './AdvanceSwap';
import SwapSetting from './components/SwapSetting';
import useIsMobile from '../../../hooks/useIsMobile';
import PageLayout from '../../../components/layout/PageLayout';
import PageHeader from '../../../components/PageHeader/PageHeader';
import { RootDispatch, RootState } from '../../../state/store';
import { useRematchDispatch } from '../../../hooks/useRematchDispatch';
import ChainNotValidBlockWrapper from '../../components/ChainNotValidBlock';
import { getColorThemeSelector } from '../../../utils/funcs';
import NetworkSelect from '../../../components/Select/NetworkSelect/NetworkSelect';
import { useTranslation } from 'react-i18next';
import CustomButton from '../../../iZUMi-UI-toolkit/src/components/Buttons/CustomButton/CustomButton';
import { i_text_copy } from '../../../iZUMi-UI-toolkit/src/style';
import useLayoutType from '../../../hooks/useLayoutType';
import { LayoutStateEnum } from '../../../state/models/pageLayout/pageLayout';
import { WarningInfo } from '../../../components/WarningInfo/WarningInfo';
import { links } from '../../../config/links';
import { useWeb3WithDefault } from '../../../hooks/useWeb3WithDefault';
import { Footer } from '../../../components/Footer/Footer';

const Swap: React.FC = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const { dispatch } = useRematchDispatch((dispatch: RootDispatch) => ({
        dispatch,
    }));
    const { tradeMode } = useSelector((state: RootState) => state);
    const layoutType = useLayoutType();
    const { chainId } = useWeb3WithDefault();

    const isSimpleMode = tradeMode.isSimpleMode;

    const [showWarning, setShowWarning] = useState(true);

    const setIsSimpleMode = useCallback(
        (e: boolean) => {
            dispatch.tradeMode.setIsSimpleMode(e);
        },
        [dispatch]
    );

    const location = useLocation();
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const oneLineMode = useBreakpointValue({ base: false, xl: true })!;

    useEffect(() => {
        const locationState = location.state as any;
        if (locationState) {
            setIsSimpleMode(false);
        }
        document.title = 'SURFI | The first native DEX Aggregator on Linea, focusing on Layer2 ecosystem.';
    }, [location.state, setIsSimpleMode, t]);

    const {
        tradeSwap: { swapForm },
    } = useSelector((state: RootState) => state);
    const swapFormTokenY = swapForm.tokenY ?? {};

    const tabValue = 'Swap';
    const handleChangeTab = (value: string) => {
        if (value === 'Limit Order') {
            history.push('/limit');
        }
    };
    const isMobile = useIsMobile();
    const isXXl = useBreakpointValue({ base: false, xxl: true, '2xl': true });

    return isMobile ? (
        <PageLayout
            body={
                <ChainNotValidBlockWrapper
                    content={
                        <Center pt="7px" w="100%">
                            <SwapForm type="mobile" setIsSimpleMode={setIsSimpleMode} />
                        </Center>
                    }
                    app="trade"
                />
            }
            type="mobile"
        />
    ) : (
        <PageLayout
            header={isSimpleMode ? <Box h="0px"></Box> : <Box h="0px"></Box>}
            body={
                isSimpleMode ? (
                    <ChainNotValidBlockWrapper
                        content={
                            <Center pt="56px" w={{ base: '100%', sm: '637px', xxl: '100%' }} mt="0px !important" alignItems="start">
                                <Stack>
                                    <SwapForm setIsSimpleMode={setIsSimpleMode} />
                                </Stack>
                            </Center>
                        }
                        app="trade"
                    />
                ) : (
                    <Stack w="100%">
                        <ChainNotValidBlockWrapper
                            content={
                                <Stack w={{ base: '100%', sm: '100%', xxl: '100%' }} mt="56px">
                                    <AdvanceSwap
                                        tabValue="Swap"
                                        isSimpleMode={isSimpleMode}
                                        setIsSimpleMode={setIsSimpleMode}
                                    ></AdvanceSwap>
                                </Stack>
                            }
                            app="trade"
                        />
                    </Stack>
                )
            }
            footer={<Footer pb="142px" pt="120px"></Footer>}
        />
    );
};

export default Swap;
